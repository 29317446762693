import React, { useEffect, useState } from "react";
import { client } from "../../../client";

import { Link } from "react-router-dom";
const Services1 = () => {
  const [sectors, setSectors] = useState([]);
  const numAscending = [...sectors].sort(
    (a, b) => a.sectorNumber - b.sectorNumber
  );
  console.log(numAscending);
  useEffect(() => {
    const query = '*[_type == "sectors"]';

    client.fetch(query).then((data) => {
      console.log(data);
      setSectors(data);
    });
  }, []);

  

  return (
    <div className="row">
      {numAscending.map((item) => (
        <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
          <div className="feature-container  feature-bx4">
            <div className="icon-content text-left">
              <Link to={item.slug.current}>
                <h4 className="m-t20 m-b20 ">{item.sectorNumber}</h4>
              </Link>
              <Link to={item.slug.current}>
                <h5 className="m-t20 m-b20 ">
                 
                 {item.sectorTitle}
                </h5>
              </Link>
              <Link to={item.slug.current}>
               
                <p className=" text-dark">
                 
                  <small>{item.sectorDesc}</small>
                </p>
              </Link>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Services1;
