import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { urlFor, client } from "../../../client";

// Layout
import Header from "../../layout/header/header3";
import Footer from "../../layout/footer/footer2";

// Elements

import ScrollToTop from "../../elements/ScrollToTop";
import PortableText from "react-portable-text";
import Sectors from "../../elements/slider/sectors";
import Slider from "../../elements/slider/slider";
import Projects from "../../elements/slider/projects";
import UpcomingProjects from "../../elements/slider/upcomingProjects";
import Disclaimer from "./popup";

const Index = () => {
  const [home, setHome] = useState([]);

  useEffect(() => {
    const query = '*[_type == "home"]';

    client.fetch(query).then((data) => setHome(data));
  }, []);

  const [sectors, setSectors] = useState([]);
  const numAscending = [...sectors].sort(
    (a, b) => a.sectorNumber - b.sectorNumber
  );
  console.log(numAscending);

  useEffect(() => {
    const query = '*[_type == "sectors"]';
    client.fetch(query).then((data) => {
      console.log(data);
      setSectors(data);
    });
  }, []);

  const [setShow] = useState(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Disclaimer onLoad={handleShow} />
      <Header />
      <ScrollToTop />
      <Slider />

      <div className="section-area section-sp4 mt--10 bg-black">
        <div className="container ">
          <h3 className="text-white">What We Do?</h3>
          <p className=" text-white m-b20">
            Pacific India is a privately owned and operated group of companies
            that has been continuously leaving a mark for 30 years in the
            commercial sectors and service industries of Real Estate, Education,
            Shopping Centres and Hospitality. The group has been operating with
            the corporate philosophy of trust, quality and commitment and has
            been able to build a great reputation and goodwill amongst its
            clients.
          </p>
          <Link to={"about"} className="button-xs btn-outline text-white m-b30">
            Read More{" "}
            <small>
              <i className="ti-arrow-right"></i>
            </small>{" "}
          </Link>
        </div>
      </div>

      <div className="section-area section-sp4 bg-gray">
        {home.map((home, index) => (
          <div className="container" key={home.name + index}>
            <div className="row ">
              <div
                className="col-lg-6 wow fadeInUp m-md-b30"
                data-wow-delay="0.4s">
                <div className=" text-center m-b10">
                  <img src={urlFor(home.aboutImage).url()} alt="" />
                </div>
              </div>
              <div className="col-lg-6 wow fadeInLeft" data-wow-delay="0.8s">
                <h3 className="title-head  text-dark  m-b0">
                  {home.aboutTitle}
                </h3>
                <hr />
                <PortableText
                  // Pass in block content straight from Sanity.io
                  content={home.aboutDesc}
                  projectId="z91hod28"
                  dataset="production"
                  // Optionally override marks, decorators, blocks, etc. in a flat
                  // structure without doing any gymnastics
                />

                <Link
                  to={"corporate-profile"}
                  className="button-sm btn text-white">
                  Read More{" "}
                  <small>
                    <i className="ti-arrow-right"></i>
                  </small>{" "}
                </Link>
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="section-area p-t50  p-b0 bg-white">
        <div className="container">
          <div
            className="heading-bx wow fadeInLeft style2 text-left"
            data-wow-delay="0.3s">
            <span>
              <h3 className=" m-b0">Our Projects</h3> <hr /> <br />
            </span>
          </div>
        </div>
        <Projects />
      </div>

      <div className="section-area section-sp4 bg-white">
        <div className="container">
          <div
            className="heading-bx wow fadeInLeft style2 text-left"
            data-wow-delay="0.3s">
            <span>
              <h3 className=" m-b0">Our Upcoming Projects</h3> <hr /> <br />
            </span>
          </div>
        </div>
        <UpcomingProjects />
      </div>

      <div className="section-area section-sp bg-primary">
        <div className="container-fluid">
          <div className=" row text-center text-white style3">
            <div className="col-md-6 phone-center ">
              <h4 className="title-head p-t10 ">Connect with Pacific Group</h4>
            </div>
            <div className="col-md-6 phone-center align-items-center p-t10">
              <Link
                to={"/contact"}
                className="btn outline button-md m-b10 white">
                Get Started Now
              </Link>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Index;
