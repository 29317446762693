import React from "react";
import { Link } from "react-router-dom";

// Layout
import Header from "../../layout/header/header3";
import Footer from "../../layout/footer/footer2";

// Elements
import ScrollToTop from "../../elements/ScrollToTop";
import bannerPic1 from "../../../images/career.webp";


const Careers = () => {


  return (
    <>
      <Header />
      <ScrollToTop />

      <div className="page-content bg-white">
        <div
          className="page-banner ovbl-dark  ovdt2 parallax"
          style={{ backgroundImage: "url(" + bannerPic1 + ")" }}
        >
         
        </div>

        <div className="content-block" id="content-area">
          <div className="section-area section-sp4 ">
            <div className="container ">
              <div className="text-left ">
                <h4>CAREER</h4> <hr />
                <h6>IT’S YOUR CAREER. MAKE IT MATTER.</h6>
                  <p>
                    Pacific India Group has always been the best destination for those who are willing to achieve new heights in career. The
                    group is based out of National Capital Region and also have presence in Uttarakhand and Western U.P. The group has
                    successfully delivered some finest creations in other parts of eastern India as well. We are always on a lookout for the best
                    in the industry, so if you want to join the best - then just go through the vacancies given below and don't hesitate to apply.
                  </p>
                  <p>
                    Send resume to: <br />
                    <Link onClick={()=> window.open("mailto:recruitment@pacificindia.in", "_blank")}>
                    <i className="ti-email"></i>  recruitment@pacificindia.in
                    </Link>   
                  </p>
                </div>
            </div>
          </div>
          <div className="section-area ">
            <div className="container  ">
               <h4 className="mb-0">Join Us</h4>
               <h6>Current Openings</h6>
           </div>
            <div className="container table-responsive">
                <table className=" mt-4 mb-5 new-table  table-bordered">
                   <tr>
                     <th>Position</th>
                     <th>No. of position</th>
                     <th>Gender</th>
                     <th>Qualification</th>
                     <th>Experience</th>
                     <th>CTC</th>
                     <th>Industry</th>
                     <th>Location</th>
                     <th>Job Description</th>
                   </tr>
                   <tr>
                      <td>Site Engineer  </td>
                      <td>1</td>
                      <td>Male</td>
                      <td>Diploma/B.E/B.Tech - Electrical</td>
                      <td>8+ Yrs.</td>
                      <td>As per industry standard</td>
                      <td>Real Estate</td>
                      <td>Jaipur</td>
                      <td>
                        <Link className="btn btn-primary btn-small" to="https://drive.google.com/file/d/1zvDDVN37jWNLx1Re6OH3QIbd47zgL_W0/view" target="_blank">View Description</Link>
                        </td>
                   </tr>
                   <tr>
                      <td>Shift Engineer</td>
                      <td>1</td>
                      <td>Male</td>
                      <td>Diploma/B.E/B.Tech - Electrical</td>
                      <td>6-8 Yrs.</td>
                      <td>As per industry standard</td>
                      <td>Real Estate</td>
                      <td>Delhi NCR/ Dehradun</td>
                      <td>
                        <Link className="btn btn-primary btn-small" to="https://drive.google.com/file/d/1Dm1FCG0LSBk37hY_y00buWpZ4BZdvbvC/view" target="_blank">View Description</Link>
                        </td>
                   </tr>
                   <tr>
                      <td>Executive Operations</td>
                      <td>1</td>
                      <td>Male</td>
                      <td>Any Graduate</td>
                      <td>2-3 Yrs.</td>
                      <td>As per industry standard</td>
                      <td>Mall/Retail</td>
                      <td>Faridabad</td>
                      <td>
                        <Link className="btn btn-primary btn-small" to="https://drive.google.com/file/d/1M_UQseFr8sZ-GDPkVRLuab7tBpSJmrPw/view?usp=sharing" target="_blank">View Description</Link>
                        </td>
                   </tr>
                   <tr>
                      <td>Executive Collections</td>
                      <td>1</td>
                      <td>Male</td>
                      <td>B.Com.</td>
                      <td>2-5 Yrs.</td>
                      <td>As per industry standard</td>
                      <td>Real Estate/Malls</td>
                      <td>Corporate Office</td>
                      <td>
                        <Link className="btn btn-primary btn-small" to="https://drive.google.com/file/d/1N8gYmeDt5x6hbsQMmzWSHFm748L7Yt33/view?usp=sharing" target="_blank">View Description</Link>
                      </td>
                   </tr>
                   <tr>
                      <td>Customer Relationship Executive</td>
                      <td>1</td>
                      <td>Male/Female</td>
                      <td>Graduate</td>
                      <td>2+ Yrs.</td>
                      <td>As per industry standard</td>
                      <td>Real Estate/Retail - Mall</td>
                      <td>Delhi/NCR</td>
                      <td>
                        <Link className="btn btn-primary btn-small" to="https://drive.google.com/file/d/1pmXNHl5AJBlCjB8_S07_erUEsv-jXGRs/view" target="_blank">View Description</Link>
                        </td>
                   </tr>
                   <tr>
                      <td>Assitant Manager - Marketing </td>
                      <td>1</td>
                      <td>Male/Female</td>
                      <td>Post Graduate (M.B.A - Mktg)</td>
                      <td>8+ Yrs</td>
                      <td>As per industry standard</td>
                      <td>Mall/Retail</td>
                      <td>Faridabad</td>
                      <td>
                        <Link className="btn btn-primary btn-small" to="https://drive.google.com/file/d/1kkJSr_1GiDcajjtzG4Qa0kbykHbKnRxt/view?usp=sharing" target="_blank">View Description</Link>
                        </td>
                   </tr>
                </table>
            </div>
          </div>
          <div className="section-area section-sp5 bg-primary">
            <div className="container">
              <div className=" row text-center text-white style3">
                <div className="col-md-6 phone-center">
                  <h4 className="title-head p-t10 ">Connect with Pacific Group</h4>
                </div>
                <div className="col-md-6 phone-center align-items-center p-t10 ">
                    <Link
                      to={"/contact"}
                      className="btn outline button-md  white"
                    >
                    Get Started Now
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Careers;
