import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { urlFor, client } from '../../../client';

const Slider1 = () => {
  
    const settings = {
      infinite: true,
      speed: 1200,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrow: true,
      autoplay: true,
    };

const [slider, setSlider] = useState([]);

useEffect(() => {
    const query = '*[_type == "slider"]';

    client.fetch(query).then((data) => setSlider(data))
  }, []);

    return (
      <>
        <Slider
          {...settings}
          className="tt-slider arrow-none slider-two slider-three slider-sp0 "
        >
          {slider.map((item) => (
          <div className="slider-item">
            <div className="slider-thumb  ovbl-middle">
              <img src={urlFor(item.sliderImage)} alt="" />
            </div>
          </div>
          ))}	

         
        </Slider>
      </>
    );
}

export default Slider1;
