import React from "react";

// Layout
import Header from "../../layout/header/header3";
import Footer from "../../layout/footer/footer2";

// Elements
import ScrollToTop from "../../elements/ScrollToTop";
import bannerPic1 from "../../../images/refund-policy.webp";

const Refund = () => {


  return (
    <>
      <Header />
      <ScrollToTop />

      <div className="page-content bg-white">
        <div
          className="page-banner ovbl-dark  ovdt2 parallax"
          style={{ backgroundImage: "url(" + bannerPic1 + ")" }}
        >
         
        </div>

        <div className="content-block" id="content-area">
         
            <div className="section-area section-sp4 ">
              <div className="container">
                <div className="feature-container feature-bx3">
                  <h4>CANCELLATION & REFUND POLICY</h4> <hr />
                  <p>FAQs:- <br /><br />
                    <ul>
                      <p>Tenants using the premises of Pacific Mall, are requested to make their monthly/quarterly rental or utility payments only on the designated payment application/url.</p>
                      <p>In case of not receiving the successful response after doing the payments we request you to please wait for at least 24 hours, before making a fresh payment.</p>
                      <p>Avoid making the payments on the last minute to face the penalty.</p>
                      <p>In case of not receiving your debited amount(Unsuccessful Order), back to you account within 7-10 working days, please write to us at (Email Id), specific mall located email id.</p>
                      <p>For some reason, the money got deducted and order/receipt is not issued, refund will be initiated automatically and money will be credited back to your account which is used for the transaction, within 7-10 Working Days.</p>
                      <p>For other payment/billing related queries and if you didn’t receive your funds as per point 5, Please write us on (Email Id).</p>
                      <p>Mode of Refund- Applicable refund will be credited to your credit card/netbanking/upi/wallet account which was used for processing the online transaction within 7-10 working days.</p>
                    </ul>
                  </p>
                </div>
               
              </div>
            </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Refund;
