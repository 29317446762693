// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.alert{
	font-family: Ubuntu;
	border-radius: 4px;
	padding: 15px 20px;
}
.alert.alert-sm {
    padding: 5px 15px;
    font-size: 12px;
}
.alert.alert-lg {
    padding: 25px 15px;
    font-size: 16px;
}
.alert.alert-xl {
    padding: 35px 15px;
    font-size: 18px;
}
.alert[class*="alert-"] i {
    margin-right: 8px;
}
.alert.no-radius {
    border-radius: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
}
.alert.no-bg {
    background-color: transparent;
    border-width: 2px;
}
.alert[class*="alert-"] ul {
    padding-left: 25px;
    margin-top: 10px;
}
.alert[class*="alert-"] ul li:before {
    color: #a94442;
}
.alert-warning{
	background-color: #fff3cd;
    border-color: #ffeeba;
    color: #856404;
}
.alert-success {
    background-color: #d4edda;
    border-color: #c3e6cb;
    color: #155724;
}
.alert-info {
    background-color: #cce5ff;
    border-color: #b8daff;
    color: #004085;
}
.alert-danger {
    background-color: #f8d7da;
    border-color: #f5c6cb;
    color: #721c24;
}
.close {
    color: #000000;
    font-weight: 400;
}`, "",{"version":3,"sources":["webpack://./src/css/shortcodes/alert.css"],"names":[],"mappings":"AAAA;CACC,mBAAmB;CACnB,kBAAkB;CAClB,kBAAkB;AACnB;AACA;IACI,iBAAiB;IACjB,eAAe;AACnB;AACA;IACI,kBAAkB;IAClB,eAAe;AACnB;AACA;IACI,kBAAkB;IAClB,eAAe;AACnB;AACA;IACI,iBAAiB;AACrB;AACA;IACI,gBAAgB;IAChB,wBAAwB;IACxB,qBAAqB;AACzB;AACA;IACI,6BAA6B;IAC7B,iBAAiB;AACrB;AACA;IACI,kBAAkB;IAClB,gBAAgB;AACpB;AACA;IACI,cAAc;AAClB;AACA;CACC,yBAAyB;IACtB,qBAAqB;IACrB,cAAc;AAClB;AACA;IACI,yBAAyB;IACzB,qBAAqB;IACrB,cAAc;AAClB;AACA;IACI,yBAAyB;IACzB,qBAAqB;IACrB,cAAc;AAClB;AACA;IACI,yBAAyB;IACzB,qBAAqB;IACrB,cAAc;AAClB;AACA;IACI,cAAc;IACd,gBAAgB;AACpB","sourcesContent":[".alert{\n\tfont-family: Ubuntu;\n\tborder-radius: 4px;\n\tpadding: 15px 20px;\n}\n.alert.alert-sm {\n    padding: 5px 15px;\n    font-size: 12px;\n}\n.alert.alert-lg {\n    padding: 25px 15px;\n    font-size: 16px;\n}\n.alert.alert-xl {\n    padding: 35px 15px;\n    font-size: 18px;\n}\n.alert[class*=\"alert-\"] i {\n    margin-right: 8px;\n}\n.alert.no-radius {\n    border-radius: 0;\n    -webkit-border-radius: 0;\n    -moz-border-radius: 0;\n}\n.alert.no-bg {\n    background-color: transparent;\n    border-width: 2px;\n}\n.alert[class*=\"alert-\"] ul {\n    padding-left: 25px;\n    margin-top: 10px;\n}\n.alert[class*=\"alert-\"] ul li:before {\n    color: #a94442;\n}\n.alert-warning{\n\tbackground-color: #fff3cd;\n    border-color: #ffeeba;\n    color: #856404;\n}\n.alert-success {\n    background-color: #d4edda;\n    border-color: #c3e6cb;\n    color: #155724;\n}\n.alert-info {\n    background-color: #cce5ff;\n    border-color: #b8daff;\n    color: #004085;\n}\n.alert-danger {\n    background-color: #f8d7da;\n    border-color: #f5c6cb;\n    color: #721c24;\n}\n.close {\n    color: #000000;\n    font-weight: 400;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
