import React from "react";

// Layout
import Header from "../../layout/header/header3";
import Footer from "../../layout/footer/footer2";

// Elements
import ScrollToTop from "../../elements/ScrollToTop";
import bannerPic1 from "../../../images/privacy.webp";

const Privacy = () => {
  

  return (
    <>
      <Header />
      <ScrollToTop />

      <div className="page-content bg-white">
        <div
          className="page-banner ovbl-dark  ovdt2 parallax"
          style={{ backgroundImage: "url(" + bannerPic1 + ")" }}
        >
         
        </div>

        <div className="content-block" id="content-area">
         
            <div className="section-area section-sp4 ">
              <div className="container">
                <div className="feature-container feature-bx3">
                  <h4>PRIVACY POLICY</h4> <hr />
                  <p>The Pacific Malls (“we”) are committed to protecting and respecting your privacy. This policy (together with our terms of use and any other documents referred to on it) sets out the basis on which any personal data we collect from you, or that you provide to us, will be processed by us. Please read the following carefully to understand our views and practices regarding your personal data and how we will treat it.</p>
				  <p>"Information we may collect from you -"</p>
				  <p>We may collect and process the following data about you and your children:</p>
				  <p>Information that you provide by filling in forms on our site <a href="http://www.pacificmalls.in/">http://www.pacificmalls.in/</a>(“our site”). This includes information provided at the time of subscribing to our parent area or requesting further information. We may also ask you for information when you report a problem with our site;</p>
				  <p>If you contact us, we may keep a record of that correspondence;</p>
				  <p>We may also ask you to complete surveys that we use for research purposes, although you do not have to respond to them; and details of your visits to our site and the resources that you access.</p>
                  
                  <h6>IP ADDRESSES AND COOKIES</h6>
                  <p>We may collect information about your computer, including where available your IP address, operating system and browser type, for system administration and to report aggregate information to our advertisers. This is statistical data about our users’ browsing actions and patterns, and does not identify any individual.</p>
				 <p>For the same reason, we may obtain information about your general internet usage by using a cookie file which is stored on the hard drive of your computer. Cookies contain information that is transferred to your computer’s hard drive. They help us to improve our site and to deliver a better and more personalised service. They enable us:</p>
				 <p>To estimate our audience size and usage pattern;</p>
				 <p>To store information about your preferences, and so allow us to customise our site according to your individual interests;</p>
				 <p>To speed up your searches; and</p>
				 <p>To recognise you when you return to our site.</p>
				 <p>You may refuse to accept cookies by activating the setting on your browser which allows you to refuse the setting of cookies. However, if you select this setting you may be unable to access certain parts of our site. Unless you have adjusted your browser setting so that it will refuse cookies, our system will issue cookies when you log on to our site.</p>


                  <h6>USES MADE OF THE INFORMATION</h6>
				  <p>We use information held about you and your children in the following ways:</p>
				  <p>To ensure that content from our site is presented in the most effective manner for you and for your computer;</p>
				  <p>To provide you with information that you request from us or which we feel may interest you, where you have consented to be contacted for such purposes;</p>
				  <p>To carry out our obligations arising from any contracts entered into between you and us; and</p>
				  <p>To notify you about changes to our service.</p>

                  
                  <h6>DISCLOSURE OF YOUR INFORMATION</h6>
				  <p>We may disclose personal information held about you and your children to third parties:</p>
				  <p>In the event that we dispose of, acquire or merge with another school, in which case we may disclose personal data collected from you.</p>
				  <p>If The Pacific Malls are acquired by a third party, in which case personal data held by us about our students and parents will be transferred.</p>
				  <p>If we are under a duty to disclose or share personal data collected from you in order to comply with any legal obligation, or in order to enforce or apply our terms of use or other agreements; or to protect the rights, property, or safety of The Pacific Malls.</p>

                 

                  <h6>ACCESS TO INFORMATION</h6>
				  <p>The Act gives you the right to access information held about you. Your right of access can be exercised in accordance with the Act. Any access request may be subject to a fee of Rs. 500 to meet our costs in providing you with details of the information we hold about you.</p>

				  
				  <h6>CHANGES TO OUR PRIVACY POLICY</h6>
				  <p>Any changes we may make to our privacy policy in the future will be posted on this page and, where appropriate, notified to you by e-mail.</p>
                 
                  <h6>CONTACT</h6>
				  <p>Questions, comments and requests regarding this privacy policy are welcomed and should be addressed to <a href="info@pacificindia.in">info@pacificindia.in</a></p>
                 
                  
                  
                </div>
                
              </div>
            </div>
         

         
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Privacy;
