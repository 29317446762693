import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { urlFor, client } from "../../../client";
import Slider from "react-slick";

// Layout
import Header from "../../layout/header/header3";
import Footer from "../../layout/footer/footer2";

// Elements
import ScrollToTop from "../../elements/ScrollToTop";
import hotelPic1 from "../../../images/hotels.png";
import foodPic1 from "../../../images/foodCourts.png";

const settings = {
  infinite: true,
  speed: 1200,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrow: true,
  autoplay: true,
};

const Hospitality = () => {

  const [foodCourt, setFoodCourt] = useState([]);

  useEffect(() => {
    const query = '*[_type == "foodCourt"]';

    client.fetch(query).then((data) => setFoodCourt(data));
  }, []);
 

  return (
    <>
      <Header />
      <ScrollToTop />
      <Slider
          {...settings}
          className="tt-slider arrow-none slider-two slider-three slider-sp0 "
        >
          {foodCourt.map((item) => (
          <div className="slider-item">

             
            <div className="slider-thumb ovbl-middle ">
              <img src={urlFor(item.bannerImage)} alt="" />
            </div>
           
            <div className="slider-content">
              <div className="container">
                <div className="content-inner ">
                
                  <h2 className="title text-white">
                  {item.sliderTitle} </h2>                  
                  
                </div>
              </div>
            </div>
          </div>
          ))}	  
        </Slider>

      <div className="page-content bg-white">
        {/* <div
          className="page-banner ovbl-dark  ovdt2 parallax"
          style={{ backgroundImage: "url(" + bannerPic1 + ")" }}
        >
         
        </div> */}

        <div className="content-block" id="content-area">
          <div className="section-area section-sp4 ">
            <div className="container ">
              <div className="text-left m-b50">
                <h4>HOSPITALITY</h4> <hr />
                <p>
                  Pacific India has become a leading name in the hospitality
                  sector and has acquired a wide presence in this domain.
                  Working according to meticulous business model, the group has
                  succeeded in completing a couple of hospitality projects well
                  within a justified frame of time.
                </p>
              </div>
              <div className="row">
                {/* <div className="col-md-6">
                  <div className="feature-container text-center feature-bx2">
                  <Link
                    to={"/hotels"}> <img src={hotelPic1} alt="" /> </Link> <hr />
                    <h6>HOTELS</h6>
                  </div>
                </div> */}

                <div className="col-md-6">
                  <div className="feature-container text-center feature-bx2">
                  <Link
                    to={"/food-court"}> <img src={foodPic1} alt="" /></Link> <hr />
                    <h6>FOOD COURTS</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="section-area section-sp5 bg-primary">
            <div className="container">
              <div className=" row text-center text-white style3">
                <div className="col-md-6 phone-center ">
                  <h4 className="title-head p-t10 ">
                  Connect with Pacific Group
                  </h4>
                </div>
                <div className="col-md-6 phone-center align-items-center p-t10 ">
                  <Link
                    to={"/contact"}
                    className="btn outline button-md  white"
                  >
                    Get Started Now
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Hospitality;
