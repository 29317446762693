import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { urlFor, client } from "../../../client";
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";
// Layout
import Header from "../../layout/header/header3";
import Footer from "../../layout/footer/footer2";

// Elements
import ScrollToTop from "../../elements/ScrollToTop";
import bannerPic1 from "../../../images/media.webp";

const options = {
  settings: {
    overlayColor: "rgba(0,0,0,0.9)",
    backgroundColor: "#0090ff",
    slideAnimationType: "slide",
  },
  buttons: {
    backgroundColor: "#0090ff",
    iconColor: "rgba(255, 255, 255, 1)",
  },
  caption: {
    captionColor: "#232eff",
    captionFontFamily: "Ubuntu, sans-serif",
    captionFontWeight: "300",
    captionTextTransform: "uppercase",
  },
};

const Media = () => {
  const [media, setMedia] = useState([]);

  useEffect(() => {
    const query = '*[_type == "media"]';

    client.fetch(query).then((data) => setMedia(data));
  }, []);


  return (
    <>
      <Header />
      <ScrollToTop />

      <div className="page-content bg-gray">
        <div
          className="page-banner ovbl-dark  ovdt2 parallax"
          style={{ backgroundImage: "url(" + bannerPic1 + ")" }}
        ></div>

        <div className="content-block" id="content-area">
          <div className="section-area section-sp4 bg-white">
            <div className="container ">
              <div className="text-left m-b50">
                <h4>MEDIA COVERAGE</h4> <hr />
              </div>

              

              <SimpleReactLightbox>
                <SRLWrapper options={options}>
                  <div className="magnific-image row">
                    {media
                      .sort((a, b) => b.num - a.num)
                      .map((item) => (
                        <div className="col-md-4">
                         <div className="portfolio-box style-1 bg-white">
                      <div className="portfolio-media">
                      <img
                            className="card-img-top"
                            src={urlFor(item.cardImage)}
                            alt=""
                          />
                      </div>
                      <div className="portfolio-info">
                        <h4 className="title">
                        {item.cardTitle}
                        </h4>
                       
                      </div>
                    </div>
                         
                        </div>
                      ))}
                  </div>
                </SRLWrapper>
              </SimpleReactLightbox>

              {/* <Masonry className="row m-b30">
                {media.sort((a, b) => b.num - a.num).map((item) => (
                  <div className="col-md-4 ">
                    <div className=" feature-container feature-bx3 text-center">
                      
                     <img src={urlFor(item.cardImage)} alt="" />
                     <h4 className=" m-t30 m-b10">{item.cardTitle}</h4>
                   
                    </div> 
                  </div>
                ))}
              </Masonry> <hr /> */}
            </div>
          </div>

          <div className="section-area section-sp5 bg-primary">
            <div className="container">
              <div className=" row text-center text-white style3">
                <div className="col-md-6 phone-center ">
                  <h4 className="title-head p-t10 ">
                    Connect with Pacific Group
                  </h4>
                </div>
                <div className="col-md-6 phone-center align-items-center p-t10 ">
                  <Link
                    to={"/contact"}
                    className="btn outline button-md  white"
                  >
                    Get Started Now
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Media;
