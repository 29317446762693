import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { client } from "../../../client";
import PortableText from "react-portable-text";
import Sectors from "../../elements/slider/sectors";

// Layout
import Header from "../../layout/header/header3";
import Footer from "../../layout/footer/footer2";
import Slider from "../../elements/slider/slider-inner";

// Elements
import ScrollToTop from "../../elements/ScrollToTop";

const Mission = () => {
  const [mission, setMission] = useState([]);

  useEffect(() => {
    const query = '*[_type == "mission"]';

    client.fetch(query).then((data) => setMission(data));
  }, []);

  return (
    <>
      <Header />
      <ScrollToTop />
      <Slider />

      <div className="page-content bg-white">
        <div className="content-block" id="content-area">
          {mission.map((item) => (
            <div className="section-area section-sp4 ">
              <div className="container">
                <div className="feature-container ">
                  <h4>MISSION & VALUES</h4> <hr />
                  <p>
                    <PortableText
                      className=""
                      // Pass in block content straight from Sanity.io
                      content={item.missionValues}
                      projectId="z91hod28"
                      dataset="production"
                      // Optionally override marks, decorators, blocks, etc. in a flat
                      // structure without doing any gymnastics
                    />
                  </p>
                </div>
                <hr />
                <div className="feature-container ">
                  <p>
                    <PortableText
                      className=""
                      // Pass in block content straight from Sanity.io
                      content={item.vision}
                      projectId="z91hod28"
                      dataset="production"
                      // Optionally override marks, decorators, blocks, etc. in a flat
                      // structure without doing any gymnastics
                    />
                  </p>
                </div>
                <hr />
                <div className="feature-container ">
                  <p>
                    <PortableText
                      className=""
                      // Pass in block content straight from Sanity.io
                      content={item.values}
                      projectId="z91hod28"
                      dataset="production"
                      // Optionally override marks, decorators, blocks, etc. in a flat
                      // structure without doing any gymnastics
                    />
                  </p>
                </div>
                <hr />
                {/* <div className="p-10 m-t80">
                  <h5 className="m-b30 m-t30">
                    TODAY PACIFIC INDIA HAS MARKED THEIR PRESENCE ACROSS THE
                    FOLLOWING BROAD VERTICALS
                  </h5>
                  <Sectors />
                </div> */}
              </div>
            </div>
          ))}

          <div className="section-area section-sp5 bg-primary">
            <div className="container">
              <div className=" row text-center text-white style3">
                <div className="col-md-6 phone-center ">
                  <h4 className="title-head p-t10 ">
                    Connect with Pacific Group
                  </h4>
                </div>
                <div className="col-md-6 phone-center align-items-center p-t10 ">
                  <Link
                    to={"/contact"}
                    className="btn outline button-md  white"
                  >
                    Get Started Now
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Mission;
