import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { urlFor, client } from "../../../client";

// Layout
import Header from "../../layout/header/header3";
import Footer from "../../layout/footer/footer2";

// Elements
import ScrollToTop from "../../elements/ScrollToTop";
import Slider from "../../elements/slider/slider-inner";

const OurProject = () => {

    const [shopping, setShopping] = useState([]);

    const numAscending = [...shopping].sort(
      (a, b) => a.projectNumber - b.projectNumber
    );

    useEffect(() => {
      const query = '*[_type == "shopping"]';
  
      client.fetch(query).then((data) => setShopping(data));
    }, []);

    const [education, setEducation] = useState([]);

    useEffect(() => {
      const query = '*[_type == "education"]';
  
      client.fetch(query).then((data) => setEducation(data));
    }, []);

    const [foodCourt, setFoodCourt] = useState([]);

  useEffect(() => {
    const query = '*[_type == "foodCourt"]';

    client.fetch(query).then((data) => setFoodCourt(data));
  }, []);

    const [hotels, setHotels] = useState([]);

  useEffect(() => {
    const query = '*[_type == "hotels"]';

    client.fetch(query).then((data) => setHotels(data));
  }, []);

    const [commercial, setCommercial] = useState([]);

  useEffect(() => {
    const query = '*[_type == "commercial"]';

    client.fetch(query).then((data) => setCommercial(data));
  }, []);


    const [residential, setResidential] = useState([]);

  useEffect(() => {
    const query = '*[_type == "residential"]';

    client.fetch(query).then((data) => setResidential(data));
  }, []);
 


  return (
    <>
      <Header />
      <ScrollToTop />
      <Slider />

      <div className="page-content bg-white">
       

        <div className="content-block" id="content-area">
          <div className="section-area section-sp4 ">
            <div className="container ">
              <div className="text-left m-b50">
                <h4>OUR PROJECTS</h4> <hr />

                <h5>Real Estate</h5>
                <hr />
                
              </div>
              <div className="row">
              {/* {residential.map((item) => (
                  <div className="col-md-4 left">
                    <div className="portfolio-box style-1 m-b50">
                      <div className="portfolio-media">
                       <Link to={"/real-estate/" + item.slug.current}> <img src={urlFor(item.thumbImage)} alt="" /></Link> 
                      </div>
                      <div className="portfolio-info">
                        <h4 className="title">
                          <Link to={"/real-estate/" + item.slug.current}>{item.title}</Link>
                        </h4>
                       
                      </div>
                    </div>
                  </div>
                ))} */}

{commercial.map((item) => (
                  <div className="col-md-4 left">
                    <div className="portfolio-box style-1 m-b50">
                      <div className="portfolio-media">
                       <Link to={"/real-estate/" + item.slug.current}> <img src={urlFor(item.thumbImage)} alt="" /></Link> 
                      </div>
                      <div className="portfolio-info">
                        <h4 className="title">
                          <Link to={"/real-estate/" + item.slug.current}>{item.title}</Link>
                        </h4>
                       
                      </div>
                    </div>
                  </div>
                ))}
              </div>
             

              <div className="text-left m-b50">
               
               <h5>Shopping Center</h5>
               <hr />
               </div>
               <div className="row">
               {numAscending.map((item) => (
                   <div className="col-md-4 left">
                     <div className="portfolio-box style-1 m-b50">
                       <div className="portfolio-media">
                         <Link onClick={()=> window.open(`${item.link}`, "_blank")} ><img src={urlFor(item.image)} alt="" /></Link>
                       </div>
                       <div className="portfolio-info">
                         <h4 className="title">
                           <Link onClick={()=> window.open(`${item.link}`, "_blank")}>{item.title}</Link>
                         </h4>
                        
                       </div>
                     </div>
                   </div>
                 ))}
               </div>

              {/* <div className="text-left m-b50">
               
              <h5>Education</h5>
              <hr />
              </div> 
              <div className="row">
              {education.map((item) => (
                  <div className="col-md-4 left">
                    <div className="portfolio-box style-1 m-b50">
                      <div className="portfolio-media">
                        <Link onClick={()=> window.open(`${item.link}`, "_blank")} ><img src={urlFor(item.image)} alt="" /></Link>
                      </div>
                      <div className="portfolio-info">
                        <h4 className="title">
                          <Link onClick={()=> window.open(`${item.link}`, "_blank")}>{item.title}</Link>
                        </h4>
                       
                      </div>
                    </div>
                  </div>
                ))}
              </div> */}

              <div className="text-left m-b50">
               
               <h5>Hospitality</h5>
               <hr />
               </div>
               <div className="row">
                 {foodCourt.sort((a, b) => a.projectNumber - b.projectNumber).map((item) => (
                   <div className="col-md-4 left">
                     <div className="portfolio-box style-1 m-b50">
                       <div className="portfolio-media">
                        <Link to={"/hotels/" + item.slug.current}>
                          <img src={urlFor(item.foodImage)} alt="" />
                        </Link> 
                       </div>
                       <div className="portfolio-info">
                         <h4 className="title">
                           <Link to={"/hotels/" + item.slug.current}>{item.foodTitle}</Link>
                         </h4>
                        
                       </div>
                     </div>
                   </div>
                 ))}
                 {/* {hotels.map((item) => (
                   <div className="col-md-4 left">
                     <div className="portfolio-box style-1 m-b50">
                       <div className="portfolio-media">
                        <Link to={"/hotels/" + item.slug.current}><img src={urlFor(item.hotelImage)} alt="" /></Link> 
                       </div>
                       <div className="portfolio-info">
                         <h4 className="title">
                           <Link to={"/hotels/" + item.slug.current}>{item.title}</Link>
                         </h4>
                        
                       </div>
                     </div>
                   </div>
                 ))} */}
               </div>
            </div>
          </div>

          <div className="section-area section-sp5 bg-primary">
            <div className="container">
              <div className=" row text-center text-white style3">
                <div className="col-md-6 phone-center ">
                  <h4 className="title-head p-t10 ">
                    Connect with Pacific Group
                  </h4>
                </div>
                <div className="col-md-6 phone-center align-items-center p-t10 ">
                  <Link
                    to={"/contact"}
                    className="btn outline button-md  white"
                  >
                    Get Started Now
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default OurProject;
