import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { client } from "../../../client.js";

// Layout
import Header from "../../layout/header/header3";
import Footer from "../../layout/footer/footer2";


export default function Blogpost() {
  const [blog, setBlog] = useState([]);
  const { slug } = useParams();

  useEffect(() => {
    client.fetch(
      `*[slug.current == "${slug}"]{ title, slug, mainImage{ asset -> { _id, url}}, body, }`
      )
      .then((data) => setBlog(data[0]))
      .catch(console.error);
  }, [slug]);



  return (
    <>
      <Header />

      <div className="page-content bg-gray">
        <div className="content-block" id="content-area">
          <div className="section-area section-sp2 bg-white">
            <div className="container">
              <div className="row">
                  <div className="col-md-12 col-lg-12 col-xl-12 m-md-b50">
                    <div className="">
                      {blog.title && (
                      <h2>{blog.title}</h2>
                      )} <br />
                      <div className="action-box blog-lg m-b30">
                        {blog.mainImage && ( 
                        <img
                          loading="lazy"
                          src={blog.mainImage.asset.url}
                          alt=""
                        />
                        )}
                      </div>
                      <div className="info-bx ">
                     
                        <div className="ttr-post-text">
                        
                        </div>
                     
                        <div className="ttr-divider bg-gray">
                          <i className="icon-dot c-square"></i>
                        </div>
                        <div className="ttr-post-meta d-flex">
                          <div className="share-post ml-auto">
                            <ul className="social">
                              <li>Share:</li>
                              <li>
                                <Link to={"#"}>
                                  <i className="fa fa-facebook"></i>
                                </Link>
                              </li>
                              <li>
                                <Link to={"#"}>
                                  <i className="fa fa-twitter"></i>
                                </Link>
                              </li>
                              <li>
                                <Link to={"#"}>
                                  <i className="fa fa-linkedin"></i>
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
              
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
   </>
  );
};

