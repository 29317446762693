// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ttr-divider {
    height: 1px;
    position: relative;
    margin: 30px 0;
}
.ttr-divider.tb20{
	margin:20px 0;
}
.ttr-divider.tb15{
	margin:15px 0;
}
.ttr-divider.tb10{
	margin:10px 0;
}
.ttr-divider.tb0{
	margin:0;
}
.ttr-divider.divider-2px {
    height: 2px;
}
.ttr-divider.divider-3px {
    height: 3px;
}
.ttr-divider.divider-4px {
    height: 4px;
}
.ttr-divider i {
    position: absolute;
    width: 30px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    display: block;
    border-radius: 100%;
    transform: translateY(-50%);
}
.ttr-divider.icon-left {
    margin-left: 40px;
}
.ttr-divider.icon-left i {
    left: -40px;
}
.ttr-divider.icon-right {
    margin-right: 40px;
}
.ttr-divider.icon-right i {
    left: auto;
    right: -40px;
}
.ttr-divider.icon-center i {
    left: 50%;
    margin-left: -5px;
}`, "",{"version":3,"sources":["webpack://./src/css/shortcodes/divider.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,kBAAkB;IAClB,cAAc;AAClB;AACA;CACC,aAAa;AACd;AACA;CACC,aAAa;AACd;AACA;CACC,aAAa;AACd;AACA;CACC,QAAQ;AACT;AACA;IACI,WAAW;AACf;AACA;IACI,WAAW;AACf;AACA;IACI,WAAW;AACf;AACA;IACI,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,iBAAiB;IACjB,cAAc;IACd,mBAAmB;IAGnB,2BAA2B;AAC/B;AACA;IACI,iBAAiB;AACrB;AACA;IACI,WAAW;AACf;AACA;IACI,kBAAkB;AACtB;AACA;IACI,UAAU;IACV,YAAY;AAChB;AACA;IACI,SAAS;IACT,iBAAiB;AACrB","sourcesContent":[".ttr-divider {\n    height: 1px;\n    position: relative;\n    margin: 30px 0;\n}\n.ttr-divider.tb20{\n\tmargin:20px 0;\n}\n.ttr-divider.tb15{\n\tmargin:15px 0;\n}\n.ttr-divider.tb10{\n\tmargin:10px 0;\n}\n.ttr-divider.tb0{\n\tmargin:0;\n}\n.ttr-divider.divider-2px {\n    height: 2px;\n}\n.ttr-divider.divider-3px {\n    height: 3px;\n}\n.ttr-divider.divider-4px {\n    height: 4px;\n}\n.ttr-divider i {\n    position: absolute;\n    width: 30px;\n    height: 30px;\n    text-align: center;\n    line-height: 30px;\n    display: block;\n    border-radius: 100%;\n    -webkit-transform: translateY(-50%);\n    -ms-transform: translateY(-50%);\n    transform: translateY(-50%);\n}\n.ttr-divider.icon-left {\n    margin-left: 40px;\n}\n.ttr-divider.icon-left i {\n    left: -40px;\n}\n.ttr-divider.icon-right {\n    margin-right: 40px;\n}\n.ttr-divider.icon-right i {\n    left: auto;\n    right: -40px;\n}\n.ttr-divider.icon-center i {\n    left: 50%;\n    margin-left: -5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
