import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { urlFor, client } from "../../../client";
import Sectors from "../../elements/slider/sectors";

// Layout
import Header from "../../layout/header/header3";
import Footer from "../../layout/footer/footer2";
import Slider from "../../elements/slider/slider-inner";

// Elements
import ScrollToTop from "../../elements/ScrollToTop";

const Milestones = () => {
  const [milestonesNew, setMilestonesNew] = useState([]);
  const numAscending = [...milestonesNew].sort((a, b) => a.number - b.number);
  console.log(numAscending);
  

  useEffect(() => {
    const query = '*[_type == "milestonesNew"]';

    client.fetch(query).then((data) => setMilestonesNew(data));
  }, []);



  return (
    <>
      <Header />
      <ScrollToTop />
      <Slider />


      <div className="page-content bg-white">
       

        <div className="content-block" id="content-area">
          <div className="section-area section-sp4 ">
            <div className="container">
              <div className="m-b50">
                <h4 className="title-head text-left">MILESTONES</h4>
                <hr />
              </div>

              <div>
                {milestonesNew.map((item) => (
                  <img src={urlFor(item.milestoneImage)} alt="" />
                ))}
               
                 
               
              </div>
              {/* <div className="p-10 m-t50">
                <h5 className="m-b30 m-t30">
                  TODAY PACIFIC INDIA HAS MARKED THEIR PRESENCE ACROSS THE
                  FOLLOWING BROAD VERTICALS
                </h5>
                <Sectors />
              </div> */}
            </div>
          </div>

          <div className="section-area section-sp5 bg-primary">
            <div className="container">
              <div className=" row text-center text-white style3">
                <div className="col-md-6 phone-center ">
                  <h4 className="title-head p-t10 ">
                  Connect with Pacific Group
                  </h4>
                </div>
                <div className="col-md-6 phone-center align-items-center p-t10 ">
                  <Link
                    to={"/contact"}
                    className="btn outline button-md  white"
                  >
                    Get Started Now
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Milestones;
