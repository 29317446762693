import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { urlFor, client } from "../../../client";
import Slider from "react-slick";

// Layout
import Header from "../../layout/header/header3";
import Footer from "../../layout/footer/footer2";

// Elements
import ScrollToTop from "../../elements/ScrollToTop";

import project1 from "../../../images/education/Pacific World School.jpg"
import project2 from "../../../images/education/DPS Indirapuram.jpg"
import project3 from "../../../images/education/DPS R N Extension.jpg"

const content = [
	{ 
		thumb: project1, 		
	},
  { 
		thumb: project2, 		
	},
  { 
		thumb: project3, 		
	},
 
	
]

const settings = {
  infinite: true,
  speed: 1200,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrow: true,
  autoplay: true,
};


const Education = () => {

  const [education, setEducation] = useState([]);

  useEffect(() => {
    const query = '*[_type == "education"]';

    client.fetch(query).then((data) => setEducation(data));
  }, []);


  return (
    <>
      <Header />
      <ScrollToTop />
      <Slider
          {...settings}
          className="tt-slider arrow-none slider-two slider-three slider-sp0 "
        >
          {content.map((item) => (
          <div className="slider-item">

             
            <div className="slider-thumb ovbl-middle ">
              <img src={item.thumb} alt="" />
            </div>
           
            <div className="slider-content">
              <div className="container">
                <div className="content-inner ">
                
                  <h2 className="title text-white">
                  {item.sliderTitle} </h2>
                  
                  
                </div>
              </div>
            </div>
          </div>
          ))}	  
        </Slider>

      <div className="page-content bg-white">
        {/* <div
          className="page-banner ovbl-dark  ovdt2 parallax"
          style={{ backgroundImage: "url(" + bannerPic1 + ")" }}
        >
         
        </div> */}

        <div className="content-block" id="content-area">
          <div className="section-area section-sp4 ">
            <div className="container ">
              <div className="text-left">
                <h4>EDUCATION</h4> <hr />
                <p>
                  Pacific India has also marked their presence in the field of
                  Education with social welfare intact in it. SAKET EDUCATION
                  SOCIETY is a Social and Educational Face of the Pacific. It's
                  an organization registered under the Societies Registration
                  Act with the broad objective of promoting education.
                  Incorporated on 14 September 2001, the SAKET EDUCATION SOCIETY
                  started with DELHI PUBLIC SCHOOL, INDIRAPURAM on 1 April 2002.
                </p>
                <p>
                  The group believes in extending education Facilities to the
                  underprivileged masses and through this they can most
                  effectively attain this social objective. The first endeavor
                  of the society towards achieving its goals is the start of
                  DELHI PUBLIC SCHOOL, INDIRAPURAM. SAKET EDUCATION SOCIETY has
                  joined hands with DPS Society, which is accredited and has a
                  chain of more than 100 schools running under this name, to
                  promote DELHI PUBLIC SCHOOL, INDIRAPURAM in 8 Acres area and
                  is successfully running the show. The Group commits its
                  support to this initiative.
                </p>
                <p>
                  The notion of good corporate citizenship and business ethics
                  is not new to Pacific. That's a commitment we've embraced
                  since our inception and our firm and employees have proudly
                  carried out the same for years as a tradition. Just as we seek
                  to be the most trusted provider of lifetime building solution
                  for our customers, we seek to be a trusted and responsive
                  member of the communities we serve as well.
                </p>
                <h6>
                  "Being educated should not be the privilege of rich alone"
                </h6>
                <p>
                  Among its many endeavors, SAKET EDUCATION SOCIETY takes care
                  of many poor and needy students by providing not just free
                  education but free books and uniforms too, under the scheme of
                  a Shiksha Kendra. DPS genuinely feels the need to encourage a
                  spirit of togetherness and an altruistic attitude towards the
                  under privileged sections of our society. DPS Shiksha Kendra
                  is a concept, it is a challenge: it is a pioneerina effort to
                  reach out to the weaker sections of the society.
                </p>
                <div className="row  m-t50">
                {education.map((item) => (
                  <div className="col-md-4 left">
                    <div className="portfolio-box style-1 ">
                      <div className="portfolio-media">
                        <Link onClick={()=> window.open(`${item.link}`, "_blank")} ><img src={urlFor(item.image)} alt="" /></Link>
                      </div>
                      <div className="portfolio-info">
                        <h4 className="title">
                          <Link onClick={()=> window.open(`${item.link}`, "_blank")}>{item.title}</Link>
                        </h4>
                       
                      </div>
                    </div>
                  </div>
                ))}
                </div>
              </div>
            </div>
          </div>

          <div className="section-area section-sp5 bg-primary">
            <div className="container">
              <div className=" row text-center text-white style3">
                <div className="col-md-6 phone-center ">
                  <h4 className="title-head p-t10 ">
                  Connect with Pacific Group
                  </h4>
                </div>
                <div className="col-md-6 phone-center align-items-center p-t10 ">
                  <Link
                    to={"/contact"}
                    className="btn outline button-md  white"
                  >
                    Get Started Now
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Education;
