import React, { useEffect, useState } from 'react';
import {Link } from 'react-router-dom';
import { urlFor, client } from "../../../client";

// Layout
import Header from '../../layout/header/header3';
import Footer from '../../layout/footer/footer2';
import ScrollToTop from "../../elements/ScrollToTop";

const Blog = () => {

	const [blog, setBlog] = useState([]);

  useEffect(() => {
    const query = '*[_type == "blog"]';

    client.fetch(query).then((data) => setBlog(data));
  }, []);
	
	
		return (
			<>
				<Header />
				<ScrollToTop />
				
				<div className="page-content bg-white">
				<div className="page-banner page-banner-sm bg-light">
						<div className="container">
						<div className="page-banner-entry">
							<div className="breadcrumb-row">
							<ul className="list-inline">
								<li style={{ color: "#0090ff" }}>
								<Link to="/">
									<i className="fa fa-home"></i>Home
								</Link>
								</li>
								<li style={{ color: "#525252" }}>Blogs</li>
							</ul>
							</div>
							<h1 style={{ color: "#0090ff" }}>Latest Blogs</h1>
						</div>
						</div>  
					</div>
								
					<div className="content-block" id="content-area">
						
						<div className="section-area section-sp2 bg-white" >
							<div className="container">
								<div className="row">
								{blog && blog.length > 0 ? (
										blog.map(blog => (
											<div className="col-lg-4 col-md-6 col-sm-12" key={blog.slug.current}>
												<div className="recent-news style-2">
													<div className="action-box">
														<img loading='lazy' src={urlFor(blog.mainImage).url()} alt=""/>
													</div>
													<div className="info-bx">
														<h5 className="post-title"><Link to={blog.slug}>{blog.title}</Link></h5>
														<p>{blog.desc}</p>
														<div className="post-extra">
															<Link to={'/blog/' + blog.slug.current} className="button-xs">
																READ MORE <small className="right"><i className="ti-arrow-right"></i></small>
															</Link>
														</div>
													</div>
												</div>
											</div>
										))
									) : (
										<h4 className='text-center' style={{color: "#bbb"}}>Content not available</h4>
									)}
									
								</div>
							</div>
						</div>					
						
					</div>
					
				</div>
				
				<Footer />
				
			</>
		);
	
}

export default Blog;