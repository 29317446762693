import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { client } from "../../../client";
import Sectors from "../../elements/slider/sectors";
import Slider from "../../elements/slider/slider-inner";

// Layout
import Header from "../../layout/header/header3";
import Footer from "../../layout/footer/footer2";

// Elements
import ScrollToTop from "../../elements/ScrollToTop";

const Vigil = () => {
  const [vigil, setVigil] = useState([]);

  useEffect(() => {
    const query = '*[_type == "vigil"]';

    client.fetch(query).then((data) => setVigil(data));
  }, []);

  return (
    <>
      <Header />
      <ScrollToTop />
      <Slider />


      <div className="page-content bg-white">
        
        <div className="content-block" id="content-area">
          {vigil.map((item) => (
            <div className="section-area section-sp4 ">
              <div className="container">
                <div className="feature-container">
                  <h4>VIGIL MECHANISM</h4> <hr />
                  <h6>VIGIL MECHANISM / WHISTLE BLOWER POLICY</h6> <br />
                  
                  <h6>PREFACE</h6> 
                  <p>Pursuant to provisions of section 177 of the Companies Act, 2013, certain class of companies shall establish a Whistle Blower policy / Vigil Mechanism for the directors and employees to report genuine concerns or grievances about unethical behavior, actual or suspected fraud or violation of the company’s Ethics. Such a vigil mechanism shall provide for adequate safeguards against victimization of directors and employees who avail of such mechanism and also make provisions for direct access to the Chairperson of Audit Committee in exceptional cases.</p>
                  
                  <h6>POLICY OBJECTIVES</h6>
                  <p>The Company is committed to adhere to the highest standards of ethical, moral and legal conduct of business operations. To maintain these standards, the Company encourages its employees who have concerns about suspected misconduct to come forward and express these concerns without fear of punishment or unfair treatment. A Vigil (Whistle Blower) mechanism provides a channel to the employees and Directors to report to the management concerns about unethical behavior, actual or suspected fraud or violation of the Codes of conduct or legal or regulatory requirements incorrect or misrepresentation of any financial statements and reports, etc.</p>

                  <h6>SCOPE OF THE POLICY</h6>
                  <p>This Policy intends to cover serious concerns that could have grave impact on the operations and performance of the business of the Company and malpractices and events which have taken place / suspected to have taken place, misuse or abuse of authority, fraud or suspected fraud, violation of company rules, manipulations, negligence causing danger to public health and safety, misappropriation of monies, and other matters or activity on account of which the interest of the Company is affected and formally reported by whistle blowers concerning its employees.</p>
                  <p>The policy neither releases employees from their duty of confidentiality in the course of their work, nor is it a route for taking up a grievance about a personal situation.</p>

                  <h6>DEFINITIONS</h6>
                  <p>
                    <ul>
                      <li>“Audit Committee” means the Audit Committee constituted by the Board of Directors of the Company in accordance with Section 292A /Sec 177 of the Companies Act, 1956/ Companies Act 2013.</li>
                      <li>“Employee” means every employee of the Company, including the Directors in the employment of the Company.</li>
                      <li>“Protected Disclosure” means a concern raised by a written communication made in good faith that discloses or demonstrates information that may evidence unethical or improper activity. Protected Disclosures should be factual and not speculative in nature.</li>
                      <li>“Subject” means a person against or in relation to whom a Protected Disclosure has been made or evidence gathered during the course of an investigation.</li>
                      <li>“Whistle Blower” means an Employee making a Protected Disclosure under this Policy.</li>
                      <li>“Whistle and Ethics Officer” means an officer of the company nominated by Competent Authority to conduct detailed investigation under this policy and to receive protected disclosure from Whistle blowers, maintain record thereof, placing the same before the Audit Committee for its disposal and informing the Whistle blower the results thereof.</li>
                      <li>“Company” means Pacific Development Corporation Limited.</li>
                    </ul>
                  </p>

                  <h6>ELIGIBILITY</h6>
                  <p>All Employees of the Company are eligible to make Protected Disclosures under the Policy in relation to matters concerning the Company.</p>

                  <h6>RECEIPT AND DISPOSAL OF PROTECTED DISCLOSURES:</h6>
                  <p>
                    <ul>
                      <li>All Protected Disclosures should be reported in writing by the complainant as soon as possible after the Whistle Blower becomes aware of the same so as to ensure a clear understanding of the issues raised and should either be typed or written in a legible handwriting in English.</li>
                      <li>The Protected Disclosure should be submitted in a closed and secured envelope and should be super scribed as “Protected disclosure under the Whistle Blower policy”. Alternatively, the same can also be sent through email with the subject “Protected disclosure under the Whistle Blower policy”. If the complaint is not super scribed and closed as mentioned above, it will not be possible for the Audit Committee to protect the complainant and the protected disclosure will be dealt with as if a normal disclosure. In order to protect identity of the complainant, the Whistle & Ethics Officer will not issue any acknowledgement to the complainants and they are advised neither to write their name/address on the envelope nor enter into any further correspondence with the Whistle & Ethics Officer. The Whistle & Ethics Officer shall assure that in case any further clarification is required he will get in touch with the complainant.</li>
                      <li>The Company shall not entertain anonymous/ pseudonymous disclosures.</li>
                      <li>The Protected Disclosure should be forwarded under a covering letter signed by the complainant. The Whistle and ethics Officer or to the Chairman of the Audit Committee as the case may be, shall detach the covering letter bearing the identity of the Whistle Blower and process only the Protected Disclosure.</li>
                      <li>All Protected Disclosures should be addressed to the Whistle and Ethics Officer of the Company or to the Chairman of the Audit Committee in exceptional cases. The contact details of the Whistle Officer are as under:-
                        <ul className="m-t20">
                          <p> <strong>Name and Address –</strong> <br /> 
                          Mr. Anurag Gautam, Corp. - HR Head <br />
                          Pacific Development Corporation Limited <br />
                          5th Floor, Pacific Mall, Site-4, Sahibabad Industrial Area, <br />
                          Ghaziabad Delhi-NCR-201010 <br />
                           <a href="mailto:hr@pacificindia.in"> E-mail : hr@pacificindia.in</a>
                           </p>
                        </ul>
                      </li>
                      <li>Protected Disclosure against the Whistle Officer should be addressed to the Chairman of the Company and the Protected Disclosure against the Chairman of the Company should be addressed to the Chairman of the Audit Committee.
                      <ul className="m-t20">
                          <p> <strong>Name and Address of Chairman (Audit Committee):</strong> <br /> 
                          Mr. Abhishek Bansal <br />
                          Whole Time Director <br />
                          5th Floor, Pacific Mall, Site-4, Sahibabad Industrial Area, <br />
                          Ghaziabad Delhi-NCR-201010 <br />
                         <a href="mailto:eaabansal@pacificindia.in">E-mail : eaabansal@pacificindia.in</a> 
                           </p>
                        </ul>
                      </li>
                      <li>On receipt of the protected disclosure the Whistle and Ethics Officer / Chairman of the Audit Committee, as the case may be, shall make a record of the Protected Disclosure and also ascertain from the complainant whether he was the person who made the protected disclosure or not. The record will include:
                        <ul>
                          <li>Brief facts;</li>
                          <li>Whether the same Protected Disclosure was raised previously by anyone, and if so, the outcome thereof;</li>
                          <li>Whether the same Protected Disclosure was raised previously on the same subject;</li>
                          <li>Details of actions taken by Whistle Officer / Chairman for processing the complaint;</li>
                          <li>Findings of the Audit Committee;</li>
                          <li>The recommendations of the Audit Committee/ other action(s).</li>
                        </ul>
                      </li>
                      <li>The Audit Committee, if deems fit, may call for further information or particulars from the complainant.</li>
                    </ul>
                  </p>
                  
                  <h6>INVESTIGATION</h6>
                  <p>
                    <ul>
                      <li>All Protected Disclosures reported under this Policy will be thoroughly investigated by the Whistle Officers of the Company who will investigate / oversee the investigations under the authorization of the Audit committee. Chairman of the Audit Committee /Whistle officer may at its discretion consider involving any investigators for the purpose of Investigation.</li>
                      <li>The decision to conduct an investigation taken into a Protected Disclosure by itself is not an acceptance of the accusation by the Authority and is to be treated as a neutral fact-finding process because the outcome of the investigation may or may not support accusation.</li>
                      <li>The identity of a Subject will be kept confidential to the extent possible given the legitimate needs of the investigation.</li>
                      <li>Unless there are compelling reasons not to do so, Subjects will be given reasonable opportunity for hearing their side during the investigation. No allegation of wrong doing against a Subject shall be considered as maintainable unless there is good evidence in support of the allegation.</li>
                      <li>Subjects shall have a duty to co-operate with the Whistle Officer(s) / Audit Committee during investigation to the extent that such co-operation sought does not merely require them to admit guilt.</li>
                      <li>Subjects shall have right to access any document/ information for their legitimate need to clarify/ defend themselves in the investigation proceedings.</li>
                      <li>Subjects shall have a responsibility not to interfere with the investigation. Evidence shall not be withheld, destroyed or tampered with, and witnesses shall not be influenced, coached, threatened or intimidated by the Subjects.</li>
                      <li>Subjects have a right to be informed of the outcome of the investigation. If allegations are not sustained, the Subjects shall be consulted as to whether public disclosure of the investigation results would be in the best interest of the Subject and the Company.</li>
                      <li>Whistle Officer shall normally complete the investigation within 60 days of the receipt of protected disclosure.</li>
                      <li>In case of allegations against subject are substantiated by the Whistle Officer in his report, the Audit Committee shall give an opportunity to Subject to explain his side.</li>
                    </ul>
                  </p>

                  <h6>PROTECTION</h6>
                  <p>
                    <ul>
                      <li>No unfair treatment will be met out to a Whistle Blower by virtue of his/ her having reported a Protected Disclosure under this policy. The company, as a policy, condemns any kind of discrimination, harassment, victimization or any other unfair employment practice being adopted against Whistle Blowers. Complete protection will, therefore, be given to Whistle Blowers against any unfair practice like retaliation, threat or intimidation of termination / suspension of service, disciplinary action, transfer, demotion, refusal of promotion or the like including any direct or indirect use of authority to obstruct the Whistle Blower’s right to continue to perform his duties / functions including making further Protected Disclosure. The Company will take steps to minimize difficulties, which the Whistle Blower may experience as a result of making the Protected Disclosure. Thus, if the Whistle Blower is required to give evidence in criminal or disciplinary proceedings, the Company will arrange for the Whistle Blower to receive advice about the procedure, etc.</li>
                      <li>A Whistle Blower may report any violation of the above clause to the Chairman of the Audit Committee, who shall investigate into the same and recommend suitable action to the management.</li>
                      <li>The identity of the Whistle Blower shall be kept confidential to the extent possible and permitted under law.</li>
                      <li>Any other Employee assisting in the said investigation shall also be protected to the same extent as the Whistle Blower.</li>
                      
                    </ul>
                  </p>

                  <h6>SECRECY / CONFIDENTIALITY</h6>
                  <p>The complainant, Vigilance and Ethics Officer, Members of Audit Committee, the Subject and everybody involved in the process shall:
                    <ul>
                      <li>Maintain confidentiality of all matters under this Policy.</li>
                      <li>Discuss only to the extent or with those persons as required under this policy for completing the process of investigations.</li>
                      <li>Not keep the papers unattended anywhere at any time.</li>
                      <li>Keep the electronic mails / files under password and safe custody.</li>
                    </ul>
                  </p>

                  <h6>DECISION</h6>
                  <p>
                    <ul>
                      <li>If an investigation leads the Whistle and Ethics Officer / Chairman of the Audit Committee to conclude that an improper or unethical act has been committed, the Whistle Officer / Chairman of the Audit Committee shall recommend to the management of the Company to take such disciplinary or corrective action as he may deem fit. It is clarified that any disciplinary or corrective action initiated against the Subject as a result of the findings of an investigation pursuant to this Policy shall adhere to the applicable personnel or staff conduct and disciplinary procedures.</li>
                      <li>If the report of investigation is not to the satisfaction of the complainant, the complainant has the right to report the event to the appropriate legal or investigating agency. A complainant who makes false allegations of unethical & improper practices or about alleged wrongful conduct of the subject to the Whistle Officer or the Audit Committee shall be subject to appropriate disciplinary action in accordance with the rules, procedures and policies of the Company.</li>
                    </ul>
                  </p>

                  <h6>REPORTING</h6>
                  <p>The Whistle & Ethics officer shall submit a report to the Chairman of the Audit Committee on a regular basis about all Protected Disclosures referred to him/her since the last report together with the results of investigations, if any.</p>

                  <h6>ACCESS TO CHAIRMAN OF THE AUDIT COMMITTEE</h6>
                  <p>The Whistle Blower shall have right to access Chairman of the Audit Committee directly in exceptional cases and the Chairman of the Audit Committee is authorized to prescribe suitable directions in this regard.</p>

                  <h6>COMMUNICATION</h6>
                  <p>A whistle Blower policy cannot be effective unless it is properly communicated to employees. The policy should be published on the website of the company.</p>

                  <h6>RETENTION OF DOCUMENTS</h6>
                  <p>All Protected disclosures in writing or documented along with the results of Investigation relating thereto, shall be retained by the Company for a period of 7 (seven) years or such other period as specified by any other law in force, whichever is more.</p>

                  <h6>ADMINISTRATION AND REVIEW OF THE POLICY</h6>
                  <p>Annual status report on the total number of compliant received if any during the period with summary of the findings of Whistle Officer / Audit Committee and corrective steps taken should be send to the Chairman of the company.</p>

                  <h6>AMENDMENT</h6>
                  <p>The Company reserves its right to amend or modify this Policy in whole or in part, at any time without assigning any reason whatsoever.</p>

                </div>
                {/* <div className="p-10 m-t50">
                  <h5 className="m-b30 m-t30">
                    TODAY PACIFIC INDIA HAS MARKED THEIR PRESENCE ACROSS THE
                    FOLLOWING BROAD VERTICALS
                  </h5>
                  <Sectors />
                </div> */}
              </div>
            </div>
          ))}

          <div className="section-area section-sp5 bg-primary">
            <div className="container">
              <div className=" row text-center text-white style3">
                <div className="col-md-6 phone-center ">
                  <h4 className="title-head p-t10 ">
                  Connect with Pacific Group
                  </h4>
                </div>
                <div className="col-md-6 phone-center align-items-center p-t10 ">
                  <Link
                    to={"/contact"}
                    className="btn outline button-md  white"
                  >
                    Get Started Now
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Vigil;
