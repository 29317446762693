import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { client } from "../../../client";

// Layout
import Header from "../../layout/header/header3";
import Footer from "../../layout/footer/footer2";

// Elements
import ScrollToTop from "../../elements/ScrollToTop";
import bannerPic1 from "../../../images/hospitality.webp";
import hotelPic1 from "../../../images/hotels.png";

const Hospitality = () => {
  const [management, setManagement] = useState([]);
  const numAscending = [...management].sort((a, b) => a.number - b.number);
  console.log(numAscending);
  useEffect(() => {
    const query = '*[_type == "management"]';

    client.fetch(query).then((data) => setManagement(data));
  }, []);


  return (
    <>
      <Header />
      <ScrollToTop />

      <div className="page-content bg-white">
        <div
          className="page-banner ovbl-dark  ovdt2 parallax"
          style={{ backgroundImage: "url(" + bannerPic1 + ")" }}
        >
          
        </div>

        <div className="content-block" id="content-area">
          <div className="section-area section-sp4 ">
            <div className="container ">
              <div className="text-left m-b50">
                <h4>HOTELS</h4><hr />
                <p>
                Pacific Group presently owns one Hotel in Dehradun –  managed by reputed brand
owner.
                </p> 
              </div>
              <div className="row">
                <div className="col-md-6 left">
                  <div className="feature-container text-center feature-bx2">
                  <Link
                    to={"/hotels/lemontree-dehradun"}> <img src={hotelPic1} alt="" /> </Link> <hr />
                   <Link
                    to={"/hotels/lemontree-dehradun"}><h5>PACIFIC DEHRADUN</h5> </Link> 
                  </div>
                </div>

               
              </div>
            </div>
          </div>

          <div className="section-area section-sp5 bg-primary">
            <div className="container">
              <div className=" row text-center text-white style3">
                <div className="col-md-6 phone-center ">
                  <h4 className="title-head p-t10 ">
                  Connect with Pacific Group
                  </h4>
                </div>
                <div className="col-md-6 phone-center align-items-center p-t10 ">
                  <Link
                    to={"/contact"}
                    className="btn outline button-md  white"
                  >
                    Get Started Now
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Hospitality;
