import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { urlFor, client } from "../../../client";

const UpcomingProjects = () => {
  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 591,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const [upcomingProjects, setUpcomingProjects] = useState([]);
  const numAscending = [...upcomingProjects].sort(
    (a, b) => a.projectNumber - b.projectNumber
  );

  useEffect(() => {
    const query = '*[_type == "upcomingProjects"]';
    client.fetch(query).then((data) => setUpcomingProjects(data));
  }, []);

  return (
    <>
      <Slider
        {...settings}
        className="portfolio-carousel-3 style-3 slider-sp7 owl-btn-1 ovb-middle owl-btn-center-lr"
      >
        {numAscending.map((item) => (
          <div className="slider-item ">
            <div className="portfolio-box style-1 m-b0">
              <div className="portfolio-media">
                <Link to={"/upcoming-projects"}><img src={urlFor(item.projectImage)} alt="" /></Link>
              </div>
              <div className="portfolio-info">
                <h4 className="title">
                  <Link to={"/upcoming-projects"}>{item.projectTitle}</Link>
                </h4>
                
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </>
  );
};

export default UpcomingProjects;
