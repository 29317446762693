import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";

function Disclaimer(props) {
  useEffect(() => {
    setTimeout(() => {
      setShow(true);
    }, 2000);
  }, []);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  return (
    <>
     

      <Modal
        show={show}
        onHide={() => setShow(false)}        
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="feature-container feature-bx3">
          <div> 
            <h4>DISCLAIMER</h4>
            <hr />
            <p> <small>Please Note: This website is in the process of being updated
              regularly. By accessing and using this website, the viewer
              confirms that the information including any of brochures and
              marketing collaterals on this website are solely & purely for
              informational purposes only and the viewer has not relied on this
              information for making any booking/purchase in any project of the
              Company. Nothing on this website constitutes advertising,
              marketing, booking, selling or an offer for sale, or invitation to
              purchase a unit in any project by the Company. The Company is not
              liable for any consequence of any action taken by the viewer
              relying on such material/ information on this website.</small>
              
            </p>
            <p> <small>Please contact our staff and team at 011-69276600 or mail us
              at <a href="mailto:info@pacificindia.in">info@pacificindia.in</a> for the updated information or any of
              sales and marketing information and collateral.</small>
              
            </p>
          </div>
          <div className="text-center m-t50">
          <button onClick={handleClose} className="button-md btn text-white">I agree </button>
          </div>
        </div>
       
      </Modal>
    </>
  );
}

export default Disclaimer;
