import React, { Component } from "react";
import { Link } from "react-router-dom";
import Sticky from "react-stickynode";

// Images
import home from "../../../images/home.svg";
import logoB from "../../../images/logo_b.png";

class Header extends Component {
  componentDidMount() {
    // Mobile Menu sidebar function
    var btn = document.querySelector(".menuicon");
    var nav = document.querySelector(".menu-links");

    function toggleFunc() {
      btn.classList.toggle("open");
      nav.classList.toggle("show");
    }

    btn.addEventListener("click", toggleFunc);

    // Mobile Submenu open close function
    var navMenu = [].slice.call(
      document.querySelectorAll(".menu-links > ul > li")
    );
    for (var y = 0; y < navMenu.length; y++) {
      navMenu[y].addEventListener("click", function () {
        menuClick(this);
      });
    }

    function menuClick(current) {
      const active = current.classList.contains("open");
      navMenu.forEach((el) => el.classList.remove("open"));

      if (active) {
        current.classList.remove("open");
        console.log("active");
      } else {
        current.classList.add("open");
        console.log("close");
      }
    }
  }

  render() {
    return (
      <>
        {/* <!-- Header Top ==== --> */}
        <header className=" header rs-nav ">
          <Sticky enabled={true} className="sticky-header navbar-expand-lg">
            <div className="menu-bar clearfix">
              <div className="container-fluid clearfix">
                {/* <!-- Header Logo ==== --> */}
                <div className="menu-logo">
                  <Link to={"/"}>
                    <img src={logoB} width="" alt="" />
                  </Link>
                </div>
                {/* <!-- Mobile Nav Button ==== --> */}
                <button
                  className="navbar-toggler collapsed menuicon justify-content-start"
                  type="button"
                  data-toggle="collapse"
                  data-target="#menuDropdown"
                  aria-controls="menuDropdown"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span></span>
                  <span></span>
                  <span></span>
                </button>
                {/* <!-- Author Nav ==== --> */}
                <div className="secondary-menu">
                  <div className="secondary-inner"></div>
                </div>
                <div className="secondary-menu"></div>

                {/* <!-- Navigation Menu ==== --> */}
                <div
                  className="menu-links navbar-collapse collapse justify-content-center"
                  id="menuDropdown"
                >
                  <div className="menu-logo">
                    <Link to={"/"}>
                      <img src={logoB} alt="" />
                    </Link>
                  </div>
                  <ul className="nav navbar-nav">
                    <li>
                      <Link to={"/"}>
                        <img width="28px" src={home} alt="" />
                      </Link>
                    </li>                   
                    <li>
                      <Link to={"/about"}>
                        About<i className="ti-arrow-down"></i>
                      </Link>
                      <ul className="sub-menu ">
                        <li>
                          <Link to={"/corporate-profile"}>
                            <span style={{ fontSize: "14px" }}>
                              Corporate Profile
                            </span>
                          </Link>
                        </li>

                        <li>
                          <Link to={"/vigil-mechanism"}>
                            <span style={{ fontSize: "14px" }}>
                              Vigil Mechanism
                            </span>
                          </Link>
                        </li>

                        <li>
                          <Link to={"/csr-policy"}>
                            <span style={{ fontSize: "14px" }}>CSR Policy</span>
                          </Link>
                        </li>

                        <li>
                          <Link to={"/mission-values"}>
                            <span style={{ fontSize: "14px" }}>
                              Mission & Values
                            </span>
                          </Link>
                        </li>

                        <li>
                          <Link to={"/management-team"}>
                            <span style={{ fontSize: "14px" }}>
                              Management Team
                            </span>
                          </Link>
                        </li>

                        {/* <li>
                          <Link to={"/milestones"}>
                            <span style={{ fontSize: "14px" }}>Milestones</span>
                          </Link>
                        </li> */}

                        <li>
                          <Link to={"/principal-contractors-employers"}>
                            <span style={{ fontSize: "14px" }}>Principal Employer/Contractors</span>
                          </Link>
                        </li>

                        <li>
                          <Link to={"/upcoming-projects"}>
                            
                            <span style={{ fontSize: "14px" }}>
                              Upcoming Projects
                            </span>
                          </Link>
                        </li>

                       
                      </ul>
                    </li>

                    <li>
                      <Link to={"/hospitality"}>
                        <span>
                          Hospitality <i className="ti-arrow-down"></i>
                        </span>
                      </Link>
                      <ul className="sub-menu ">
                        {/* <li>
                          <Link to={"/hotels"}>
                            <span style={{ fontSize: "14px" }}>
                              Hotels <i className="ti-arrow-right"></i>
                            </span>
                          </Link>
                          <ul className="sub-menu">
                            <li>
                              <Link to={"/hotels/lemontree-dehradun"}>
                                <span>Lemon tree Dehradun</span>
                              </Link>
                            </li>
                          </ul>
                        </li> */}
                        <li>
                          <Link to={"/food-court"}>
                            <span style={{ fontSize: "14px" }}>
                              Food Court <i className="ti-arrow-right"></i>
                            </span>
                          </Link>
                          <ul className="sub-menu ml-10 ">
                            <li>
                              <Link
                                to={"/food-court/salt-pacific-mall-sahibabad"}
                              >
                                <span>Sahibabad</span>
                              </Link>
                            </li>
                            <li>
                              <Link
                                to={
                                  "/food-court/salt-pacific-mall-tagore-garden"
                                }
                              >
                                <span>Tagore Garden</span>
                              </Link>
                            </li>

                            <li>
                              <Link
                                to={"/food-court/salt-pacific-mall-dehradun"}
                              >
                                <span>Dehradun</span>
                              </Link>
                            </li>
                            <li>
                              <Link
                                to={"/food-court/salt-pacific-mall-d21-dwarka"}
                              >
                                <span>D21 Dwarka</span>
                              </Link>
                            </li>
                            <li>
                              <Link
                                to={
                                  "/food-court/salt-pacific-mall-nsp-pitampura"
                                }
                              >
                                <span>NSP-Pitampura</span>
                              </Link>
                            </li>
                            <li>
                              <Link
                                to={
                                  "/food-court/salt-mall-of-dehradun"
                                }
                              >
                                <span>Mall of Dehradun</span>
                              </Link>
                            </li>
                            <li>
                              <Link
                                to={
                                  "/food-court/salt-pacific-outlet-mall-jasola"
                                }
                              >
                                <span>Pacific Outlet Mall, Jasola</span>
                              </Link>
                            </li>
                            <li>
                              <Link
                                to={
                                  "/food-court/salt-the-mall-of-faridabad"
                                }
                              >
                                <span>Mall of Faridabad</span>
                              </Link>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>

                    <li>
                      <Link to={"/real-estate"}>
                        <span>
                          Real Estate <i className="ti-arrow-down"></i>
                        </span>
                      </Link>
                      <ul className="sub-menu ">
                        {/* <li>
                          <Link to={"#"}>
                            <span style={{ fontSize: "14px" }}>
                              Residential <i className="ti-arrow-right"></i>
                            </span>
                          </Link>
                          <ul className="sub-menu ">
                            <li>
                              <Link to={"/real-estate/pacific-hills-dehradun"}>
                                <span>Pacific Hill Dehradun</span>
                              </Link>
                            </li>

                            <li>
                              <Link
                                to={"/real-estate/pacific-golf-estate-dehradun"}
                              >
                                <span>Pacific Golf Estate Dehradun</span>
                              </Link>
                            </li>

                            <li>
                              <Link
                                to={"/real-estate/pacific-star-homes-moradabad"}
                              >
                                <span>Pacific Star Homes Moradabad</span>
                              </Link>
                            </li>
                          </ul>
                        </li> */}

                        <li>
                          <Link to={"#"}>
                            <span style={{ fontSize: "14px" }}>
                              Commercial <i className="ti-arrow-right"></i>
                            </span>
                          </Link>
                          <ul className="sub-menu ml-10 ">
                            <li>
                              <Link to={"/real-estate/pacific-business-park"}>
                                <span>Pacific Business Park</span>
                              </Link>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>

                    {/* <li>
                      <Link to={"/education"}>
                        <span>
                          Education <i className="ti-arrow-down"></i>
                        </span>
                      </Link>
                      <ul className="sub-menu ">
                        <li>
                          <Link
                            onClick={() =>
                              window.open(
                                "https://www.dpsindirapuram.com/",
                                "_blank"
                              )
                            }
                          >
                            <span style={{ fontSize: "14px" }}>
                              DPS Indirapuram
                            </span>
                          </Link>
                        </li>

                        <li>
                          <Link
                            onClick={() =>
                              window.open("https://www.dpsrnext.com/", "_blank")
                            }
                          >
                            <span style={{ fontSize: "14px" }}>
                              DPS Raj Nagar Extension
                            </span>
                          </Link>
                        </li>

                        <li>
                          <Link
                            onClick={() =>
                              window.open(
                                "https://www.pacificworldschool.com/",
                                "_blank"
                              )
                            }
                          >
                            <span style={{ fontSize: "14px" }}>
                              Pacific World School
                            </span>
                          </Link>
                        </li>
                      </ul>
                    </li> */}
                    <li>
                      <Link
                        onClick={() =>
                          window.open("https://www.pacificmalls.in")
                        }
                      >
                        <span>Shopping Center</span>
                      </Link>
                    </li>
                    {/* <li>
                      <Link to={"#"}>
                        <span>
                          Projects <i className="ti-arrow-down"></i>
                        </span>
                      </Link>
                      <ul className="sub-menu">
                        <li>
                          <Link to={"/our-project"}>
                            
                            <span style={{ fontSize: "14px" }}>
                              Our Projects
                            </span>
                          </Link>
                        </li>
                        <li>
                          <Link to={"/upcoming-projects"}>
                            
                            <span style={{ fontSize: "14px" }}>
                              Upcoming Projects
                            </span>
                          </Link>
                        </li>
                      </ul>
                    </li> */}
                    <li>
                      <Link to={"/careers"}>
                        <span>Careers</span>
                      </Link>
                    </li>
                    <li>
                      <Link to={"/media"}>
                        <span>Media</span>
                      </Link>
                    </li>
                    <li>
                      <Link to={"/contact"}>
                        <span>Contact</span>
                      </Link>
                    </li>
                  </ul>
                </div>

                {/* <!-- Navigation Menu END ==== --> */}
              </div>
            </div>
          </Sticky>
          {/* <!-- Search Box ==== --> */}
        </header>
        {/* <!-- Header Top END ==== --> */}
      </>
    );
  }
}

export default Header;
