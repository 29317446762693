import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { urlFor, client } from "../../../client";
import Slider from "react-slick";

// Layout
import Header from "../../layout/header/header3";
import Footer from "../../layout/footer/footer2";

// Elements
import ScrollToTop from "../../elements/ScrollToTop";

const settings = {
  infinite: true,
  speed: 1200,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrow: true,
  autoplay: true,
};
const FoodCourt = () => {
  const [foodCourt, setFoodCourt] = useState([]);
  const numAscending = [...foodCourt].sort(
    (a, b) => a.projectNumber - b.projectNumber
  );
  useEffect(() => {
    const query = '*[_type == "foodCourt"]';
    client.fetch(query).then((data) => setFoodCourt(data));
  }, []);

  return (
    <>
      <Header />
      <ScrollToTop />

      <Slider
          {...settings}
          className="tt-slider arrow-none slider-two slider-three slider-sp0 "
        >
          {foodCourt.map((item) => (
          <div className="slider-item">

             
            <div className="slider-thumb ovbl-middle ">
              <img src={urlFor(item.bannerImage)} alt="" />
            </div>
           
            <div className="slider-content">
              <div className="container">
                <div className="content-inner ">
                
                  <h2 className="title text-white">
                  {item.sliderTitle} </h2>
                  
                  
                </div>
              </div>
            </div>
          </div>
          ))}	  
        </Slider>


      <div className="page-content bg-white">
      

        <div className="content-block" id="content-area">
          <div className="section-area section-sp4 ">
            <div className="container ">
              <div className="text-left m-b50">
                <h4>FOOD COURTS</h4>
                <hr />
                <p>
                  Pacific India is renowned for its best food courts across its
                  Shopping Centres. The USPs of the food court include a good
                  laid out plan by the way of having dedicated entry with lift
                  for staff & vendor movement, wide service corridors
                  facilitating customers not having to share movement with
                  vendors, suppliers & staff along with an automated dishwashing
                  takes care of providing guest with clean crockery & doing away
                  with disposables. The food courts offer a wide variety of
                  national and international cuisine to choose from.
                </p>
              </div>
              <div className="row">
                {numAscending.map((item) => (
                   <div className="col-md-4 left">
                   <div className="portfolio-box style-1 m-b50">
                     <div className="portfolio-media">
                      <Link to={"/food-court/" + item.slug.current}><img src={urlFor(item.foodImage)} alt="" /></Link> 
                     </div>
                     <div className="portfolio-info">
                       <h4 className="title">
                         <Link to={"/food-court/" + item.slug.current}>{item.foodTitle}</Link>
                       </h4>
                      
                     </div>
                   </div>
                 </div>
                ))}
              </div>
            </div>
          </div>

          <div className="section-area section-sp5 bg-primary">
            <div className="container">
              <div className=" row text-center text-white style3">
                <div className="col-md-6 phone-center ">
                  <h4 className="title-head p-t10 ">
                    Connect with Pacific Group
                  </h4>
                </div>
                <div className="col-md-6 phone-center align-items-center p-t10 ">
                  <Link
                    to={"/contact"}
                    className="btn outline button-md  white"
                  >
                    Get Started Now
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default FoodCourt;
