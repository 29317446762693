import React from "react";
import { Link } from "react-router-dom";

// Layout
import Header from "../../layout/header/header3";
import Footer from "../../layout/footer/footer2";

// Elements
import ScrollToTop from "../../elements/ScrollToTop";
import bannerPic1 from "../../../images/terms-conditions.webp";

const Tnc = () => {
 

  return (
    <>
      <Header />
      <ScrollToTop />

      <div className="page-content bg-white">
        <div
          className="page-banner ovbl-dark  ovdt2 parallax"
          style={{ backgroundImage: "url(" + bannerPic1 + ")" }}
        >
         
        </div>

        <div className="content-block" id="content-area">
         
            <div className="section-area section-sp4 ">
              <div className="container">
                <div className="feature-container feature-bx3">
                  <h4>TERMS & CONDITIONS</h4> <hr />
                  <p>Thanks for using our products and services (“Services”). The Services are provided by PACIFIC DEVELOPMENT CORPORATION LTD (“Pacific Malls”). By using our Services, you are agreeing to these terms. Please read them carefully.</p>
                  
                  <h6>USING OUR SERVICES</h6>
                  <p>You must follow any policies made available to you within the Services.</p>
				  <p>Don’t misuse our Services. For example, don’t interfere with our Services or try to access them using a method other than the interface and the instructions that we provide. You may use our Services only as permitted by law, including applicable export and re-export control laws and regulations. We may suspend or stop providing our Services to you if you do not comply with our terms or policies or if we are investigating suspected misconduct.</p>
				  <p>Using our Services does not give you ownership of any intellectual property rights in our Services or the content you access. You may not use content from our Services unless you obtain permission from its owner or are otherwise permitted by law. These terms do not grant you the right to use any branding or logos used in our Services. Don’t remove, obscure, or alter any legal notices displayed in or along with our Services.</p>
				  <p>Our Services display some content that is not Pacific Malls. This content is the sole responsibility of the entity that makes it available. We may review content to determine whether it is illegal or violates our policies, and we may remove or refuse to display content that we reasonably believe violates our policies or the law. But that does not necessarily mean that we review all the content, so please don’t assume that we do.</p>
				  <p>Our primary services are on mobile devices. Do not use such Services in a way that distracts you and prevents you from obeying traffic or safety laws.</p>

                  <h6>YOUR PACIFIC</h6>
				  <p>You are provided a Pacific Malls account linked to your registered mobile number at the time of registration and subsequent login(s). We send you a One Time Password (“OTP”).</p>
				  <p>To protect your Pacific Malls Account, keep your OTP confidential. You are responsible for the activity that happens on or through your Pacific Malls Account. Try not to reuse your Pacific Malls Account on mobile phones not owned by you.</p>

                  
                  <h6>PRIVACY AND COPYRIGHT PROTECTION</h6>
				  <p>Pacific Malls’s privacy policy explains how we treat your personal data and protect your privacy when you use our Services. By using our Services, you agree that Pacific Malls can use such data in accordance with our privacy policies.</p>
				  <p>We respond to notices of alleged copyright infringement and take appropriate actions.</p>
				  <p>We provide information to help copyright holders manage their intellectual property online. If you think somebody is violating your copyrights and want to notify us, you can email us at <a href="mailto:info@pacificindia.in">info@pacificindia.in</a>.</p>

                 

                  <h6>YOUR CONTENT IN OUR SERVICES</h6>
                  <p>Some of our Services allow you to upload, submit, store, send or receive content. You retain ownership of any intellectual property rights that you hold in that content. In short, what belongs to you stays yours.</p>
				  <p>When you upload, submit, store, send or receive content to or through our Services, you give Pacific Malls (and those we work with) a worldwide license to use, host, store, reproduce, modify, create derivative works (such as those resulting from translations, adaptations or other changes we make so that your content works better with our Services), communicate, publish, publicly perform, publicly display and distribute such content. The rights you grant in this license are for the limited purpose of operating, promoting, and improving our Services, and to develop new ones. This license continues even if you stop using our Services (for example, for a business listing you have added to Pacific Malls). If we offer a content management system (“Any application”) and an mobile application for content management by our partners called Pacific Malls Store keeper’s. Make sure you have the necessary rights to grant us this license for any content that you submit to Any application of Pacific Malls or Storekeeper, Application.

</p>
<p>Our automated systems analyze your content to provide you personally relevant product features, such as customized search results. This analysis occurs as the content is sent, received, and when it is stored.</p>
<p>If you have a Pacific Malls Account, we may display your Profile name, Profile photo, and actions you take on Pacific Malls or on third-party applications connected to your Pacific Malls Account (such as reviews you write and likes) in our Services.</p>
<p>You can find more information about how Pacific Malls uses and stores content in the privacy policy or additional terms for particular Services. If you submit feedback or suggestions about our Services, we may use your feedback or suggestions without obligation to you.</p>
                  
				  
				  <h6>ABOUT SOFTWARE IN OUR SERVICES</h6>
                  <p>When a Service requires or includes downloadable software, this software may update automatically on your device once a new version or feature is available. Some Services may let you adjust your automatic update settings.</p>
				  <p>Pacific Malls gives you a personal, worldwide, royalty-free, non-assignable and non- exclusive license to use the software provided to you by Pacific Malls as part of the Services. This license is for the sole purpose of enabling you to use and enjoy the benefit of the Services as provided by Pacific Malls, in the manner permitted by these terms. You may not copy, modify, distribute, sell, or lease any part of our Services or included software, nor may you reverse engineer or attempt to extract the source code of that software, unless laws prohibit those restrictions or you have our written permission.</p>
				  <p>Open source software is important to us. Some software used in our Services may be offered under an open source license. There may be provisions in the open source license that expressly override some of these terms.</p>
                  
                  <h6>MODIFYING AND TERMINATING OUR SERVICES</h6>
                  <p>We are constantly changing and improving our Services. We may add or remove functionalities or features, and we may suspend or stop a Service altogether.</p>
                  <p>You can stop using our Services at any time, although we’ll be sorry to see you go. Pacific Malls may also stop providing Services to you, or add or create new limits to our Services at any time.</p>
                  <p>We believe that you own your data and preserving your access to such data is important. If we discontinue a Service, where reasonably possible, we will give you reasonable advance notice and a chance to get information out of that Service.</p>

                   <h6>OUR WARRANTIES AND DISCLAIMERS</h6>
                   <p>We provide our Services using a commercially reasonable level of skill and care and we hope that you will enjoy using them. But there are certain things that we don’t promise about our Services.</p>
                   <p>OTHER THAN AS EXPRESSLY SET OUT IN THESE TERMS OR ADDITIONAL TERMS, NEITHER PACIFIC MALLS NOR ITS SUPPLIERS OR DISTRIBUTORS MAKE ANY SPECIFIC PROMISES ABOUT THE SERVICES. FOR EXAMPLE, WE DON’T MAKE ANY COMMITMENTS ABOUT THE CONTENT WITHIN THE SERVICES, THE SPECIFIC FUNCTIONS OF THE SERVICES, OR THEIR RELIABILITY, AVAILABILITY, OR ABILITY TO MEET YOUR NEEDS. WE PROVIDE THE SERVICES “AS IS”.</p>
                   <p>SOME JURISDICTIONS PROVIDE FOR CERTAIN WARRANTIES, LIKE THE IMPLIED WARRANTY OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT. TO THE EXTENT PERMITTED BY LAW, WE EXCLUDE ALL WARRANTIES.</p>
                   <h6>LIABILITY FOR OUR SERVICES</h6>
                   <p>WHEN PERMITTED BY LAW, PACIFIC MALL, AND PACIFIC MALL’S SUPPLIERS AND DISTRIBUTORS, WILL NOT BE RESPONSIBLE FOR LOST PROFITS, REVENUES, OR DATA, FINANCIAL LOSSES OR INDIRECT, SPECIAL, CONSEQUENTIAL, EXEMPLARY, OR PUNITIVE DAMAGES.  </p>
                    <p>TO THE EXTENT PERMITTED BY LAW, THE TOTAL LIABILITY OF PACIFIC MALL, AND ITS SUPPLIERS AND DISTRIBUTORS, FOR ANY CLAIMS UNDER THESE TERMS, INCLUDING FOR ANY IMPLIED WARRANTIES, IS LIMITED TO THE AMOUNT YOU PAID US TO USE THE SERVICES (OR, IF WE CHOOSE, TO SUPPLYING YOU THE SERVICES AGAIN).</p>
                    <p>IN ALL CASES, PACIFIC MALL, AND ITS SUPPLIERS AND DISTRIBUTORS, WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE THAT IS NOT REASONABLY FORESEEABLE.</p>
                    <h6>BUSINESS USES OF OUR SERVICES</h6>
                    <p>If you are using our Services on behalf of a business, that business accepts these terms. It will hold harmless and indemnify pacific Malls and its affiliates, officers, agents, and employees from any claim, suit or action arising from or related to the use of the Services or violation of these terms, including any liability or expense arising from claims, losses, damages, suits, judgments, litigation costs and attorneys’ fees.</p>
                    <h6>ABOUT THESE TERMS</h6>
                    <p>We may modify these terms or any additional terms that apply to a Service to, for example, reflect changes to the law or changes to our Services. You should look at the terms regularly. If you do not agree to the modified terms for a Service, you should discontinue your use of that Service.</p>
                    <p>If there is a conflict between these terms and the additional terms, the additional terms will control for that conflict.</p>
                    <p>These terms control the relationship between Pacific Malls and you. They do not create any third party beneficiary rights.</p>
                    <p>If you do not comply with these terms, and we don’t take action right away, this doesn’t mean that we are giving up any rights that we may have (such as taking action in the future).</p>
                    <p>If it turns out that a particular term is not enforceable, this will not affect any other terms.</p>
                    <p>This Agreement is governed by, and is to be construed in accordance with Indian law. The Delhi Courts will have exclusive jurisdiction to deal with any dispute which has arisen or may arise out of, or in connection with, this Agreement and you and Pacific Malls consent to personal jurisdiction in those courts.</p>
                </div>
               
              </div>
            </div>
         
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Tnc;
