import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { urlFor, client } from "../../../client";

// Layout
import Header from "../../layout/header/header3";
import Footer from "../../layout/footer/footer2";
import Slider from "react-slick";

// Elements
import ScrollToTop from "../../elements/ScrollToTop";
import project3 from "../../../images/upcoming/Mall of Dehradun.jpg";
import project4 from "../../../images/upcoming/pacific-faridabad.jpg";

const content = [
  // {
  //   thumb: project3,
  // },
  {
    thumb: project4,
  },
];

const settings = {
  infinite: true,
  speed: 1200,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrow: true,
  autoplay: true,
};

const UpcomingProjectssss = () => {
  const [upcomingProjects, setUpcomingProjects] = useState([]);
  const numAscending = [...upcomingProjects].sort(
    (a, b) => a.projectNumber - b.projectNumber
  );

  useEffect(() => {
    const query = '*[_type == "upcomingProjects"]';
    client.fetch(query).then((data) => setUpcomingProjects(data));
  }, []);

  return (
    <>
      <Header />
      <ScrollToTop />

      <Slider
        {...settings}
        className="tt-slider arrow-none slider-two slider-three slider-sp0 ">
        {content.map((item) => (
          <div className="slider-item">
            <div className="slider-thumb ovbl-middle ">
              <img src={item.thumb} alt="" />
            </div>

            <div className="slider-content">
              <div className="container">
                <div className="content-inner ">
                  <h2 className="title text-white">{item.sliderTitle} </h2>
                </div>
              </div>
            </div>
          </div>
        ))}
      </Slider>

      <div className="page-content bg-white">
        <div className="content-block" id="content-area">
          <div className="section-area section-sp4 ">
            <div className="container ">
              <div className="text-left m-b50">
                <h4> UPCOMING PROJECTS</h4> <hr />
              </div>
              <div className="row">
                {numAscending.map((item) => (
                  <div className="col-md-4 left">
                    <div className="portfolio-box style-1 m-b50">
                      <div className="portfolio-media">
                        <img src={urlFor(item.projectImage)} alt="" />
                      </div>
                      <div className="portfolio-info">
                        <h4 className="title">{item.projectTitle}</h4>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="section-area section-sp5 bg-primary">
            <div className="container">
              <div className=" row text-center text-white style3">
                <div className="col-md-6 phone-center ">
                  <h4 className="title-head p-t10 ">
                    Connect with Pacific Group
                  </h4>
                </div>
                <div className="col-md-6 phone-center align-items-center p-t10 ">
                  <Link
                    to={"/contact"}
                    className="btn outline button-md  white">
                    Get Started Now
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default UpcomingProjectssss;
