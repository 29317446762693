import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { client } from "../../../client";
import PortableText from "react-portable-text";
import Sectors from "../../elements/slider/sectors";
import Slider from "../../elements/slider/slider-inner";

// Layout
import Header from "../../layout/header/header3";
import Footer from "../../layout/footer/footer2";

// Elements
import ScrollToTop from "../../elements/ScrollToTop";

const FuturePlans = () => {
  const [futurePlans, setFuturePlans] = useState([]);

  useEffect(() => {
    const query = '*[_type == "futurePlans"]';

    client.fetch(query).then((data) => setFuturePlans(data));
  }, []);


  return (
    <>
      <Header />
      <ScrollToTop />
      <Slider />


      <div className="page-content bg-white">
        

        <div className="content-block" id="content-area">
          <div className="section-area section-sp4 ">
            <div className="container">
              {futurePlans.map((item) => (
                <div className="feature-container ">
                  <h4>FUTURE PLANS</h4> <hr />
                  <p>
                    <PortableText
                      className="m-t30"
                      // Pass in block content straight from Sanity.io
                      content={item.future}
                      projectId="z91hod28"
                      dataset="production"
                      // Optionally override marks, decorators, blocks, etc. in a flat
                      // structure without doing any gymnastics
                      
                    />
                  </p>
                </div>
              ))}

              <div>
                <div className="video-media-bx m-b10">
                <iframe width="100%" height="400" src="https://www.youtube.com/embed/OgtiOQCSm8s" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
              </div>

              {/* <div className="p-10 m-t50">
                <h5 className="m-b30 m-t30">
                  TODAY PACIFIC INDIA HAS MARKED THEIR PRESENCE ACROSS THE
                  FOLLOWING BROAD VERTICALS
                </h5>
                <Sectors />
              </div> */}
            </div>
          </div>

          <div className="section-area section-sp5 bg-primary">
            <div className="container">
              <div className=" row text-center text-white style3">
                <div className="col-md-6 phone-center ">
                  <h4 className="title-head p-t10 ">
                  Connect with Pacific Group
                  </h4>
                </div>
                <div className="col-md-6 phone-center align-items-center p-t10 ">
                  <Link
                    to={"/contact"}
                    className="btn outline button-md  white"
                  >
                    Get Started Now
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default FuturePlans;
