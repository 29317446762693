import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
const ContactForm = ({onEnquirySubmit }) => {
  const [userData, setUserData] = useState({
    name: "",
    email: "",
    phone: "",
    message: ""
  });

  const postUserData = (event) => {
    const { name, value } = event.target;
    setUserData({ ...userData, [name]: value });
  };
  const [showModal, setShowModal] = useState(false);
  const handleClose = () => setShowModal(false);
  const submitData = (event) => {
    event.preventDefault();
    
    const { name, email, phone, message } = userData;

    if (name && email && phone && message) {
      const data = {
        service_id: 'service_p61l5w7',     // Replace with your EmailJS service ID
        template_id: 'template_4skm2rg',   // Replace with your EmailJS template ID
        user_id: '2VHXRueRE80u5mrSw',           // Replace with your EmailJS user ID
        template_params: {
          'from_name': name,
          'email': email,
          'phone': phone,
          'message': message
        }
      };

      fetch('https://api.emailjs.com/api/v1.0/email/send', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      })
      .then((response) => {
        if (response.ok) {
          setUserData({
            name: "",
            email: "",
            phone: "",
            message: ""
          });
          //  alert('Your mail is sent!');
          setShowModal(true);
          
        } else {
          throw new Error('Failed to send email');
        }
      })
      .catch((error) => {
        console.error('Error:', error);
        alert('Oops... ' + error.message);
      });
    } else {
      alert("Please fill all details.");
    }
  };

  

  return (
    <div className="contact-bx p-20 ajax-form">
      <h5 className="title-head">Enquiry Form</h5>

      <form onSubmit={submitData}>
        <div className="row placeani">
          <div className="col-lg-12">
            <div className="form-group">
              <div className="input-group">
                <input
                  name="name"
                  type="text"
                  required
                  className="form-control valid-character"
                  placeholder="Your Name"
                  value={userData.name}
                  onChange={postUserData}
                />
              </div>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="form-group">
              <div className="input-group">
                <input
                  name="email"
                  type="email"
                  className="form-control"
                  required
                  placeholder="Your Email"
                  value={userData.email}
                  onChange={postUserData}
                />
              </div>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="form-group">
              <div className="input-group">
                <input
                  name="phone"
                  type="number"
                  required
                  className="form-control int-value"
                  placeholder="Your Phone"
                  value={userData.phone}
                  onChange={postUserData}
                />
              </div>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="form-group">
              <div className="input-group">
                <textarea
                  name="message"
                  rows="2"
                  className="form-control"
                  placeholder="Type Message"
                  value={userData.message}
                  onChange={postUserData}
                  required
                ></textarea>
              </div>
            </div>
          </div>
          <div className="col-lg-12">
            <button
              name="submit"
              type="submit"
              className="btn-primary button-md radius-md"
            >
              Send Message
            </button>
          </div>
        </div>
      </form>
      <Modal
        show={showModal}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="feature-container feature-bx3 text-center">
          <div  className="p-4">
            <button onClick={handleClose} style={{ position:"absolute", right:"10px", top:"10px" }}   className="border-0 bg-dark text-white px-2 rounded ">x</button>
            <h4 className="border-0">Thank You!</h4>
           
            <p style={{ fontSize:"16px", lineHeight:"24px"}}>
              Your enquiry has been successfully submitted.
              <br />
              We appreciate your interest and will get back to you shortly.
            </p>
          </div>
          <div className="text-center m-t20">
            <button
              onClick={() => {
                handleClose();
                window.location.href = "/";
              }}
              className="button-sm btn text-white"
            >
              Back to Home
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ContactForm;
