import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { client } from "../../../client";
import PortableText from "react-portable-text";

// Layout
import Header from "../../layout/header/header3";
import Footer from "../../layout/footer/footer2";

// Elements
import ScrollToTop from "../../elements/ScrollToTop";



const Commercial = () => {
  const [commercial, setCommercial] = useState([]);
  const { slug } = useParams();

  useEffect(() => {
    client
      .fetch(
        `*[slug.current == "${slug}"]{ title, slug, thumbImage{ asset -> { _id, url}}, bannerImage{ asset -> { _id, url}}, description, }`
      )
      .then((data) => setCommercial(data[0]))
      .catch(console.error);
  }, [slug]);


  return (
    <>
      <Header />
      <ScrollToTop />

      <div className="page-content bg-white">
        {commercial.bannerImage && (
          <div
            className="page-banner ovbl-dark  ovdt2 parallax"
            style={{
              backgroundImage: `url(${commercial.bannerImage.asset.url})`,
            }}
          >
           
          </div>
        )}

        <div className="content-block" id="content-area">
          <div className="section-area section-sp4 ">
            <div className="container ">
              <div className="text-left m-b50">
                {commercial.title && <h3>{commercial.title}</h3>}
                
                <hr />
              </div>
              <div className="">
                <div className="col-md-12 left">
                  <div className="feature-container text-left ">
                    {commercial.thumbImage && (
                      <img width="500px" src={commercial.thumbImage.asset.url} alt="" />
                    )}
                    {commercial.title && (
                      <h5 className="m-t30">{commercial.title}</h5>
                    )}
                    <hr />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="feature-container ">
                    {commercial.description && (
                      <p>
                        <PortableText
                          // Pass in block content straight from Sanity.io
                          content={commercial.description}
                          projectId="z91hod28"
                          dataset="production"
                          // serializers={serializers}
                          // Optionally override marks, decorators, blocks, etc. in a flat
                          // structure without doing any gymnastics
                        />
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="section-area section-sp5 bg-primary">
            <div className="container">
              <div className=" row text-center text-white style3">
                <div className="col-md-6 phone-center ">
                  <h4 className="title-head p-t10 ">
                    Connect with Pacific Group
                  </h4>
                </div>
                <div className="col-md-6 phone-center align-items-center p-t10 ">
                  <Link
                    to={"/contact"}
                    className="btn outline button-md  white"
                  >
                    Get Started Now
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Commercial;
