import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { client } from "../../../client";
import Sectors from "../../elements/slider/sectors";
import Slider from "../../elements/slider/slider-inner";
import Table from "react-bootstrap/Table";

// Layout
import Header from "../../layout/header/header3";
import Footer from "../../layout/footer/footer2";

// Elements
import ScrollToTop from "../../elements/ScrollToTop";

const content = [
  {
    number: "1",
    particular: "Name / address of Website",
    description: "www.pacificindia.in",
  },
  {
    number: "2",
    particular: "Name of the Establishment",
    description: "Pacific Mall",
  },
  {
    number: "3",
    particular: "Address of the Establishment (Registered Office)",
    description:
      "Ground Floor-42, Pacific Mall, Tagore Garden, Najafgarh Road, New Delhi - 110018",
  },
  {
    number: "4",
    particular: "Address of the Establishment (Branch Office)",
    description:
      "Pacific Mall, Netaji Subhash Place Metro Station, Phase -3 Corridor, New Delhi-110034",
  },
  {
    number: "5",
    particular: "Nature of work for which Contract Workmen employed",
    description: "Housekeeping/Security/Maintenance/Parking",
  },
  {
    number: "6",
    particular: "Name of the Proprietor/Partner/Director/Managing Director",
    description: "Mr. Abhishek Bansal",
  },
  {
    number: "7",
    particular: "Date of starting of Business",
    description: "19th Aug’ 2021",
  },
  {
    number: "8",
    particular:
      "Date of Initial agreement with the Principal Employer (working since with PE)",
    description:
      "1st April’2021, 12th  May’2021, 1st April’2021, 25th Aug’2021",
  },
  {
    number: "9",
    particular: "Mobile No. of PE/Contractor",
    description: "+91 120-4044000",
  },
  {
    number: "10",
    particular: "Email ID of PE/Contractor",
    description: "hr@pacificindia.in",
  },
  {
    number: "11",
    particular: "Website Address of Organization",
    description: "www.pacificindia.in",
  },
  {
    number: "12",
    particular: "Registration No./Contractor labor License No. and valid up to",
    description: "PE Registration application no. 50690000001675",
  },
  {
    number: "13",
    particular: "Registration under the Registration Act, 1908",
    description: "NA",
  },
  {
    number: "14",
    particular: "Registration under Partnership Act, 1932",
    description: "NA",
  },
  {
    number: "15",
    particular: "Registration under the Companies Act, 1956",
    description: "U70100DL2004PTC130486",
  },
  {
    number: "16",
    particular:
      "Registration No. under the Delhi Shops & Establishment Act, 1956",
    description: "2021134790",
  },
  {
    number: "17",
    particular: "Registration under the Factories Act, 1956",
    description: "NA",
  },
  {
    number: "18",
    particular: "Registration No. of Employee ESI Act, 1932",
    description: "67000403420001001",
  },
  {
    number: "19",
    particular: "Registration No. EPF and Misc. Provisions Act, 1952",
    description: "UP/40532",
  },
  {
    number: "20",
    particular: "GSTIN/UIN",
    description: "07AAECA9647A1ZT",
  },
  {
    number: "21",
    particular: "CIN",
    description: "U70100DL2004PTC130486",
  },
  {
    number: "22",
    particular: "PAN Card No.",
    description: "AAECA9647A",
  },
];

const content1 = [
    {
        number: "1",
        contractor: "Alicorn Services  First Floor, 57, A-1 block, Janakpuri, New Delhi-110058  ",
        partner:"Rajiv Kumar Relan (Proprietor) of the company)",
        workplace:"Pacific Mall, Dwarka, Sector 21, West Delhi, Delhi - 110075",
        worker:"25",
        representative:"Batukeshwar Vats (Officer Administration)",
        date:"10th of every month",
    },
    {
        number: "2",
        contractor: "Proton Facility Services Private Limited Plot No.-2, 1st Floor, Phase- IV, Udyog Vihar, Gurgaon-122001 (Haryana),",
        partner:"Gaurav Saini (G.M)Operation of the company)",
        workplace:"Pacific Mall, Dwarka, Sector 21, West Delhi, Delhi - 110075",
        worker:"50",
        representative:"Batukeshwar Vats (Officer Executive Administration)",
        date:"10th of every month",
    },
    {
        number: "3",
        contractor: "SIS Limited B-88, Defence Colony, New Delhi-110024",
        partner:"A. K Bali (Regional Vice President) of the company",
        workplace:"Pacific Mall, Dwarka, Sector 21, West Delhi, Delhi - 110075",
        worker:"60",
        representative:"Batukeshwar Vats (Officer Administration)",
        date:"10th of every month",
    },
    {
        number: "4",
        contractor: "Secure Parking Solution Private Limited  Premier A- 401, Business Square, Solitaire Corporate Park, Chakala, Andheri (East) Mumbai",
        partner:"Arvind Mayar (C.E.O) of the Company",
        workplace:"P Pacific Mall, Dwarka, Sector 21, West Delhi, Delhi - 110075",
        worker:"19",
        representative:"Batukeshwar Vats (Officer Administration)",
        date:"10th of every month",
    },

];

const PCE = () => {
  const [vigil, setVigil] = useState([]);

  useEffect(() => {
    const query = '*[_type == "vigil"]';

    client.fetch(query).then((data) => setVigil(data));
  }, []);

  return (
    <>
      <Header />
      <ScrollToTop />
      <Slider />

      <div className="page-content bg-white">
        <div className="content-block" id="content-area">
          {vigil.map((item) => (
            <div className="section-area section-sp4 ">
              <div className="container">
                <div className="feature-container m-b30">
                  <h4 style={{ textTransform: "uppercase" }}>
                    Principal Employer/Contractors
                  </h4>
                  <hr />
                  <h5>PACIFIC MAINTENANCE SERVICES PVT. LIMITED</h5>

                  <Table bordered hover responsive className="table">
                    <thead className="text-left">
                      <tr className="">
                        <th ColSpan={1}>Sl. No.</th>
                        <th>Particulars / Details</th>
                        <th>Description</th>
                      </tr>
                    </thead>
                    {content.map((item, id) => (
                      <tbody key={id}>
                        <tr>
                          <td ColSpan={1}>{item.number}</td>
                          <td>{item.particular}</td>
                          <td>{item.description}</td>
                        </tr>
                      </tbody>
                    ))}
                  </Table>
                </div>

                <div className="feature-container">
                  <div className="border p-05">
                    <p>
                      
                      <strong>
                        Name and address of Principal Employer establishment:-
                      </strong>
                      Pacific Mall, Netaji Subhash Place Metro Station, Phase -3
                      Corridor, New Delhi - 110034
                    </p>
                    <p>
                      
                      <strong>
                        Name and Rgd. address of Principal Employer:-
                      </strong>
                      Pacific Maintenance Services Pvt. Limited, Ground
                      Floor-42, Pacific Mall, Tagore Garden, Najafgarh Road, New
                      Delhi - 110018
                    </p>
                  </div>

                  <Table bordered hover responsive className="table">
                    <thead className="text-left">
                      <tr className="">
                        <th ColSpan={1}>Sl. No.</th>
                        <th>Name and address of Contractor/s engaged</th>
                        <th>Name of Executive Partner / Prop. / Director</th>
                        <th>Site / Work place where contract labour is to be engaged</th>
                        <th>Maximum Number of workers to be engaged</th>
                        <th>Name & Designation of Authorized Representative of PE for verifying /witness the wage disbursement</th>
                        <th>Date of payment of Wages</th>

                      </tr>
                    </thead>
                    {content1.map((item, id) => (
                      <tbody key={id}>
                        <tr>
                          <td ColSpan={1}>{item.number}</td>
                          <td>{item.contractor}</td>
                          <td>{item.partner}</td>
                          <td>{item.workplace}</td>
                          <td>{item.worker}</td>
                          <td>{item.representative}</td>
                          <td>{item.date}</td>
                        </tr>
                      </tbody>
                    ))}
                  </Table>
                  <div className="border p-05">
                      <div className="feature-container">
                          <p>Note: The Company does not engage any contractor’s labour or have any Contract for supply of labour. We only engage Service provider to provide specialized services that are required.</p>
                      </div>
                  </div>
                </div>
                {/* <div className="p-10 m-t50">
                  <h5 className="m-b30 m-t30">
                    TODAY PACIFIC INDIA HAS MARKED THEIR PRESENCE ACROSS THE
                    FOLLOWING BROAD VERTICALS
                  </h5>
                  <Sectors />
                </div> */}
              </div>
            </div>
          ))}

          <div className="section-area section-sp5 bg-primary">
            <div className="container">
              <div className=" row text-center text-white style3">
                <div className="col-md-6 phone-center ">
                  <h4 className="title-head p-t10 ">
                    Connect with Pacific Group
                  </h4>
                </div>
                <div className="col-md-6 phone-center align-items-center p-t10 ">
                  <Link
                    to={"/contact"}
                    className="btn outline button-md  white"
                  >
                    Get Started Now
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default PCE;
