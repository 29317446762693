import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { urlFor, client } from "../../../client";

// Layout
import Header from "../../layout/header/header3";
import Footer from "../../layout/footer/footer2";
import Slider from "react-slick";

// Elements
import ScrollToTop from "../../elements/ScrollToTop";
// import project1 from "../../../images/real-estate/golf-estate.webp"
import project1 from "../../../images/real-estate/banner.webp"
// import project2 from "../../../images/real-estate/pacific-hills.webp"



const content = [
	{ 
		thumb: project1, 		
	},
  // { 
	// 	thumb: project2, 		
	// },
 
	
]

const settings = {
  infinite: true,
  speed: 1200,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrow: true,
  autoplay: true,
};

const RealEstate = () => {
  const [residential, setResidential] = useState([]);

  useEffect(() => {
    const query = '*[_type == "residential"]';

    client.fetch(query).then((data) => setResidential(data));
  }, []);

  const [commercial, setCommercial] = useState([]);
  useEffect(() => {
    const query = '*[_type == "commercial"]';
    client.fetch(query).then((data) => setCommercial(data));
  }, []);

  return (
    <>
      <Header />
      <ScrollToTop />
      <Slider
          {...settings}
          className="tt-slider arrow-none slider-two slider-three slider-sp0 "
        >
          {content.map((item) => (
          <div className="slider-item">

             
            <div className="slider-thumb ovbl-middle ">
              <img src={item.thumb} alt="" />
            </div>
           
            <div className="slider-content">
              <div className="container">
                <div className="content-inner ">
                  
                  <h2 className="title text-white">
                  {item.sliderTitle} </h2>
                  
                  
                </div>
              </div>
            </div>
          </div>
          ))}	  
        </Slider>

      <div className="page-content bg-white">
       

        <div className="content-block" id="content-area">
          <div className="section-area section-sp4 ">
            <div className="container ">
              <div className="text-left m-b50">
                <h4>REAL ESTATE</h4> <hr />
                <p>
                  Pacific India Group designs and constructs with top notch
                  standard with all the modern amenities. Spacious, beautiful
                  and magnificent are few adjectives that are highly appropriate
                  to describe the magnificence and discreetly designed structure
                  of these creations of Pacific India Group that are at
                  strategic locations of Delhi/ NCR as well as other major
                  locations of Western U.P., Dehardun and other parts of India
                </p>
              </div>
              {/* <div className="text-left m-b30">
                <h4>Residential</h4>
                
              </div>
              <div className="row m-b30">
                {residential.map((item) => (
                  <div className="col-md-4 left">
                  <div className="portfolio-box style-1 m-b50">
                    <div className="portfolio-media">
                     <Link to={"/real-estate/" + item.slug.current}> <img src={urlFor(item.thumbImage)} alt="" /></Link> 
                    </div>
                    <div className="portfolio-info">
                      <h4 className="title">
                        <Link to={"/real-estate/" + item.slug.current}>{item.title}</Link>
                      </h4>
                     
                    </div>
                  </div>
                </div>
                ))}
              </div> <hr /> */}


              <div className="text-left m-t50 m-b30">
                <h4>Commercial</h4>
                
              </div>
              <div className="row">
                {commercial.map((item) => (
                  <div className="col-md-4 left">
                  <div className="portfolio-box style-1 m-b50">
                    <div className="portfolio-media">
                     <Link to={"/real-estate/" + item.slug.current}> <img src={urlFor(item.thumbImage)} alt="" /></Link> 
                    </div>
                    <div className="portfolio-info">
                      <h4 className="title">
                        <Link to={"/real-estate/" + item.slug.current}>{item.title}</Link>
                      </h4>
                     
                    </div>
                  </div>
                </div>
                ))}
              </div>
            </div>
          </div>

          <div className="section-area section-sp5 bg-primary">
            <div className="container">
              <div className=" row text-center text-white style3">
                <div className="col-md-6 phone-center ">
                  <h4 className="title-head p-t10 ">
                  Connect with Pacific Group
                  </h4>
                </div>
                <div className="col-md-6 phone-center align-items-center p-t10 ">
                  <Link
                    to={"/contact"}
                    className="btn outline button-md  white"
                  >
                    Get Started Now
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default RealEstate;
