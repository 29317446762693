import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { client } from "../../../client";
import PortableText from "react-portable-text";
import Sectors from "../../elements/slider/sectors";
import Slider from "../../elements/slider/slider-inner";


// Layout
import Header from "../../layout/header/header3";
import Footer from "../../layout/footer/footer2";

// Elements
import ScrollToTop from "../../elements/ScrollToTop";

const Management = () => {
  const [management, setManagement] = useState([]);

  const numAscending = [...management].sort((a, b) => a.alphabet > b.alphabet);
  console.log(numAscending);

  useEffect(() => {
    const query = '*[_type == "management"]';

    client.fetch(query).then((data) => setManagement(data));
  }, []);


  return (
    <>
      <Header />
      <ScrollToTop />
      <Slider />


      <div className="page-content bg-gray">
       
        <div className="content-block" id="content-area">
          <div className="section-area section-sp4 bg-gray">
            <div className="container">
              <div className="col-md-12 ">
                <h4>MANAGEMENT TEAM</h4>
              </div><hr className="m-b50" />
              {numAscending.map((item) => (
                  <div className="col-md-12" key={item.alphabet}>
                    <div className="feature-container  ">
                      <p>
                        <PortableText
                          className="m-t30"
                          // Pass in block content straight from Sanity.io
                          content={item.management}
                          projectId="z91hod28"
                          dataset="production"
                          // Optionally override marks, decorators, blocks, etc. in a flat
                          // structure without doing any gymnastics
                          
                        />
                      </p> <hr />
                    </div>
                    <div className="feature-container ">
                      <p>
                        <PortableText
                          className="m-t30"
                          // Pass in block content straight from Sanity.io
                          content={item.management2}
                          projectId="z91hod28"
                          dataset="production"
                          // Optionally override marks, decorators, blocks, etc. in a flat
                          // structure without doing any gymnastics
                          
                        />
                      </p> <hr />
                    </div>
                    <div className="feature-container ">
                      <p>
                        <PortableText
                          className="m-t30"
                          // Pass in block content straight from Sanity.io
                          content={item.management3}
                          projectId="z91hod28"
                          dataset="production"
                          // Optionally override marks, decorators, blocks, etc. in a flat
                          // structure without doing any gymnastics
                          
                        />
                      </p> <hr />
                    </div>
                    <div className="feature-container ">
                      <p>
                        <PortableText
                          className="m-t30"
                          // Pass in block content straight from Sanity.io
                          content={item.management4}
                          projectId="z91hod28"
                          dataset="production"
                          // Optionally override marks, decorators, blocks, etc. in a flat
                          // structure without doing any gymnastics
                          
                        />
                      </p> <hr />
                    </div>
                    <div className="feature-container ">
                      <p>
                        <PortableText
                          className="m-t30"
                          // Pass in block content straight from Sanity.io
                          content={item.management5}
                          projectId="z91hod28"
                          dataset="production"
                          // Optionally override marks, decorators, blocks, etc. in a flat
                          // structure without doing any gymnastics
                          
                        />
                      </p> <hr />
                    </div>
                    <div className="feature-container ">
                      <p>
                        <PortableText
                          className="m-t30"
                          // Pass in block content straight from Sanity.io
                          content={item.management6}
                          projectId="z91hod28"
                          dataset="production"
                          // Optionally override marks, decorators, blocks, etc. in a flat
                          // structure without doing any gymnastics
                          
                        />
                      </p>
                    </div>
                    
                  </div>
                  
                  
                  
                ))}
            </div>
          </div>

          {/* <div className="section-area section-sp4 bg-white">
            <div className="container">
            <div className="head-in m-b50">
                  <h4 className="text-left">TODAY PACIFIC INDIA HAS MARKED THEIR PRESENCE ACROSS THE FOLLOWING BROAD VERTICALS</h4>
                </div>
                <div className="p-10">
                  <Sectors />
                </div>
            </div>
          </div> */}

          <div className="section-area section-sp5 bg-primary">
            <div className="container">
              <div className=" row text-center text-white style3">
                <div className="col-md-6 phone-center ">
                  <h4 className="title-head p-t10 ">
                    Connect with Pacific Group
                  </h4>
                </div>
                <div className="col-md-6 phone-center align-items-center p-t10 ">
                  <Link
                    to={"/contact"}
                    className="btn outline button-md  white"
                  >
                    Get Started Now
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Management;
